// vendor
import React, { useState } from "react";
import { Button, Form, FormGroup, CustomInput } from "reactstrap";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router";

//app
import AssessmentCodeModal, { CodeHere } from "../../components/Modals/AssessmentCodeModal";
import { validateEmail } from "../../utils";
import { FormError } from "../../components/Cards/FormCard";
import { GoBackButton } from "../../components/Buttons";
import TermsAndConditionsModal from "../../components/Modals/TermsAndConditionsModal";
import TermsAndConditionsInput from "../../components/Inputs/TermsAndConditions";
import { FREE_ASSESSMENT_PROPERTY_PRICE } from "../../constants";
import MailCheckerInput from "../../components/MailCheck";

const CURRENT_STEP = 0;

const GetAssessmentForm = ({
  onSubmit,
  setUserEmail,
  postAssessmentData,
  customerEmail,
  uuid,
  selectedAddress,
}) => {
  const history = useHistory();
  const [email, setEmail] = useState({
    value: customerEmail || "",
    error: null,
  });
  const [modal, setModal] = useState(false);
  const [checkboxChecked, setCheckbox] = useState(false);
  const [freeAssesment, setFreeAssessment] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [codeModal, setCodeModal] = useState(false);
  const [discountObject, setDiscountObect] = useState(null);

  const toggle = () => setModal(!modal);
  const toggleCodeModal = () => setCodeModal(!codeModal);

  const handleOnClick = (e) => {
    e.preventDefault();
    const errors = {};
    if (email.error || !email.value) {
      errors.email = "Must be valid email";
    }

    if (!checkboxChecked) {
      errors.checkboxChecked = "You must agree with our Terms and Conditions";
    }

    if (isEmpty(errors)) {
      setUserEmail(email.value);
      postAssessmentData(discountObject, freeAssesment === "true");
      onSubmit(CURRENT_STEP + 1);
    } else {
      setFormErrors(errors);
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const isValid = validateEmail(email);

    if (isValid && formErrors.email) {
      setFormErrors({ ...formErrors, email: "" });
    }

    setEmail({ value: email, error: !isValid });
  };

  const handleTermsAndConditionsChange = () => {
    if (formErrors.checkboxChecked) {
      setFormErrors({ ...formErrors, checkboxChecked: "" });
    }

    setCheckbox(!checkboxChecked);
  };

  const handleChangeFreeReport = (e) => {
    setFreeAssessment(e.target.value);
  };

  const handleSubmitCode = (discountObject) => {
    setDiscountObect(discountObject);
  };

  const renderFreeReportInput = () =>
    selectedAddress.appraisedData &&
    selectedAddress.appraisedData.appraised_value <
      FREE_ASSESSMENT_PROPERTY_PRICE && (
      <FormGroup>
        <small>
          We may be able to offer you a free tax contestation report. Is this
          home your primary residence?
        </small>{" "}
        <br />
        <CustomInput
          type="radio"
          id="yesRadio"
          name="customRadio"
          label="Yes"
          value={true}
          inline
          checked={freeAssesment === "true"}
          onChange={handleChangeFreeReport}
        />
        <CustomInput
          type="radio"
          id="noRadio"
          name="customRadio"
          label="No"
          inline
          value={false}
          checked={freeAssesment === "false"}
          onChange={handleChangeFreeReport}
        />
      </FormGroup>
    );

  const renderDiscountApplied = () =>
    discountObject && (
      <>
        {discountObject.discountPercentage &&
          discountObject.discountPercentage < 100 && (
            <div>
              <i>
                <b>
                  Discount code {discountObject.value} applied (
                  {discountObject.discountPercentage}% savings!)
                </b>
              </i>
            </div>
          )}
        {discountObject.discountPercentage === 100 && (
          <div>
            <i>
              <b>Free Report Code {discountObject.value} applied! </b>
            </i>
          </div>
        )}
      </>
    );

  const buttonTitle =
    (discountObject && discountObject.discountPercentage === 100) ||
    freeAssesment === "true" ||
    uuid
      ? "Send My Report"
      : "Send My Free Assessment";

  return (
    <>
      <GoBackButton onClick={() => history.push("/verify")} />
      <div className="title">
        <h5>Where should we send your free assessment? </h5>
      </div>
      <Form className="register-form" onSubmit={handleOnClick}>
        <MailCheckerInput
          inputText={email.value}
          handleEmailChange={handleEmailChange}
        >
          {renderDiscountApplied()}
          {formErrors.email && (
            <FormError>Must enter a valid Email Address.</FormError>
          )}
        </MailCheckerInput>
        {renderFreeReportInput()}
        <TermsAndConditionsInput
          onChange={handleTermsAndConditionsChange}
          error={formErrors.checkboxChecked}
          onTermsConditionsClick={toggle}
        />
        <Button
          className="btn-round mb-4"
          color="neutral"
          onClick={handleOnClick}
        >
          {buttonTitle} <i className="fa fa-chevron-right" />
        </Button>
        {!uuid && (
          <div>
            <i>
              Have a discount or preorder code? <br />
              <CodeHere onClick={toggleCodeModal}>
                Enter your code here.
              </CodeHere>
            </i>
          </div>
        )}
      </Form>
      <TermsAndConditionsModal isOpen={modal} toggle={toggle} />
      <AssessmentCodeModal
        isOpen={codeModal}
        toggle={toggleCodeModal}
        submitCode={handleSubmitCode}
      />
    </>
  );
};

export default GetAssessmentForm;
