// vendor
import React from "react";
import { Container, Row, Col } from "reactstrap";

// app
import MainNavbar from "../../components/Navbar/Navbar";
import MainFooter from "../../components/Footers/Footer";
import FreeAssesmentHeader from "../../components/Header/FreeAssesmentHeader";
import { privacyPolicy } from '../../constants/privacyPolicy'

const PrivacyPolicyPage = () => {
  return (
    <>
      <MainNavbar />
      <FreeAssesmentHeader minHeight="20vh" />
      <Container>
        <h1 className="title">Privacy Policy</h1>
        <Row>
          <Col style={{ textAlign: 'justify' }}>
            {privacyPolicy}
          </Col>
        </Row>
      </Container>
      <MainFooter />
    </>
  );
};

export default PrivacyPolicyPage;
