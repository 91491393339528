import styled, { css } from "styled-components";
import { Container } from "reactstrap";

const HeaderCSS = css`
  background-image: url(${({ image }) =>
    image || "../../assets/img/landing-page-image-2.jpg"});
`;

export const HeaderContainer = styled.div`
  ${HeaderCSS}

  min-height: 80vh;

  @media (max-width: 998.98px) {
    min-height: 100vh;
    padding-bottom: 3rem;
  }

  @media (max-height: 870px) {
    min-height: 100vh;
  }
`;

export const MottoHeaderContainer = styled(Container)`
  @media (max-height: 870px) {
    margin-top: 6.25rem;
  }
`;

export const HeaderTitle = styled.h1`
  font-weight: 500;
`;

export const HeaderSubtitleContainer = styled.div`
  max-width: 60%;

  @media (max-width: 767.98px) {
    max-width: 90%;
  }
`;

export const FreeAssesmentHeaderContainer = styled.div`
  ${HeaderCSS}

  background-position-y: 10%;
  min-height: ${({ minHeight }) => minHeight || "30vh"};
`;

export const AboutHeaderContainer = styled.div`
  ${HeaderCSS}

  min-height: 20vh;

  .container {
    .motto {
      padding: 10rem 1.2rem 8rem 1.2rem;

      h1 {
        font-weight: 800;
        text-align: center;
      }
    }
  }
`;
