import {
  TOGGLE_UNBOUNCE_MODAL,
  SUBMIT_UNBOUNCE,
  SUBMIT_UNBOUNCE_SUCCESS,
  SUBMIT_UNBOUNCE_FAIL,
} from "../actionTypes";
import {
  UNBOUNCE_SUBMITED_TIMESTAMP,
  UNBOUNCE_LOCALSTORAGE_KEY,
} from "../../constants";

const initialState = {
  unbounceModalShow: false,
  isSubmitted: false,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case TOGGLE_UNBOUNCE_MODAL:
      if (state.unbounceModalShow) {
        localStorage.setItem(UNBOUNCE_SUBMITED_TIMESTAMP, new Date().getTime());
        localStorage.setItem(UNBOUNCE_LOCALSTORAGE_KEY, true);
      }
      return { ...state, unbounceModalShow: !state.unbounceModalShow };

    case SUBMIT_UNBOUNCE:
      return { ...state, isSubmitted: true };
    case SUBMIT_UNBOUNCE_SUCCESS:
      return { ...state, isSubmitted: true };
    case SUBMIT_UNBOUNCE_FAIL:
      return { ...state, isSubmitted: false };
    default:
      return state;
  }
};
