import React from "react";

const ThankYouInfo = () => (
  <div className="title">
    <h5>
      Thank you! We are calculating your assessment and will email your results.
    </h5>
  </div>
);

export default ThankYouInfo;
