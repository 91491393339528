import axios from "axios";
import { isPreOrderFlow } from "../../configs";

import { API_URL, ASSESSES_AFTER, BROKER_REFERRAL_SESSION_STORAGE_KEY } from "../../constants";
import {
  GET_ADDRESS_SUGGESTIONS,
  GET_ADDRESS_SUGGESTIONS_SUCCESS,
  SET_ADDRESS,
  SET_USER_EMAIL,
  SET_ADDRESS_MODIFICATION,
  GET_PROPERTY_BY_ORDER,
  GET_PROPERTY_BY_ORDER_SUCCESS,
  GET_PROPERTY_BY_ORDER_FAIL,
  POST_ASSESSMENT_DATA_SUCCESS,
  POST_ASSESSMENT_DATA_FAIL,
  GET_PROPERTY_CURRENT_ASSESSMENT_FAIL,
  GET_PROPERTY_CURRENT_ASSESSMENT_SUCCESS,
  GET_PROPERTY_CURRENT_ASSESSMENT,
  POST_ASSESSMENT_DATA,
  GET_BROKER_BY_REFERRAL,
  GET_BROKER_BY_REFERRAL_SUCCESS,
  GET_BROKER_BY_REFERRAL_FAIL,
} from "../actionTypes";

export const getAddressSuggestions = (addressStr) => {
  return (dispatch) => {
    dispatch(getAddressSuggestionsStart());
    axios
      .post(`${API_URL}/properties/home_lookup_ajax/`, {
        q: addressStr,
        assessed_after: ASSESSES_AFTER,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getAddressSuggestionsSuccess(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getAddressSuggestionsStart = () => ({
  type: GET_ADDRESS_SUGGESTIONS,
});

export const getAddressSuggestionsSuccess = (items) => ({
  type: GET_ADDRESS_SUGGESTIONS_SUCCESS,
  payload: {
    items,
  },
});

export const setAddress = (address) => ({
  type: SET_ADDRESS,
  payload: {
    address,
  },
});

export const setUserEmail = (email) => ({
  type: SET_USER_EMAIL,
  payload: {
    email,
  },
});

export const setAssessmentFormValues = (values) => ({
  type: SET_ADDRESS_MODIFICATION,
  payload: {
    values,
  },
});

export const postAssessmentData = (discountObject, isFree, isPreOrder) => {
  return (dispatch, getState) => {
    dispatch(postAssessmentDataRequest());
    const { freeAssesment } = getState();
    const { selectedAddress, customerEmail, freeAssesmentFormValues, uuid } = freeAssesment;

    const referral = sessionStorage.getItem(BROKER_REFERRAL_SESSION_STORAGE_KEY);
    const changedValues = {};
    Object.keys(freeAssesmentFormValues).forEach((k) => {
      if (freeAssesmentFormValues[k] !== selectedAddress[k]) {
        changedValues[k] = freeAssesmentFormValues[k];
      }
    });

    const payload = {
      ...changedValues,
      email_address: customerEmail,
    };

    if (discountObject) {
      payload.discount_code = discountObject.value;
    }

    if (uuid) {
      payload.order_uuid = uuid;
    }

    if (!isPreOrder && !payload.discountObject) {
      payload.broker = referral;
    }

    const url = `${selectedAddress.url}begin-computation/`;
    axios
      .post(url, payload)
      .then((res) => {
        if (res.data.uuid && referral && isPreOrderFlow) {
          axios.post(`${API_URL}/orders/${res.data.uuid}/attach-broker/`, {
            broker: referral,
          });
        }

        dispatch(postAssessmentDataSuccess({ response: res, isPreOrder: isPreOrder }));
      })
      .catch((err) => {
        dispatch(postAssessmentDataError(err));
      });
  };
};

export const postAssessmentDataRequest = (payload) => ({
  type: POST_ASSESSMENT_DATA,
  payload,
});

export const postAssessmentDataSuccess = (payload) => ({
  type: POST_ASSESSMENT_DATA_SUCCESS,
  payload,
});

export const postAssessmentDataError = (err) => ({
  type: POST_ASSESSMENT_DATA_FAIL,
  payload: {
    error: err,
  },
});

export const getDiscountCode = (code) => axios.post(`${API_URL}/discount-codes/validate/`, { discount_code: code });

export const postDiscountCodeToOrder = (code, orderId) =>
  axios.post(`${API_URL}/orders/${orderId}/apply-discount/`, {
    discount_code: code,
  });

export const getPropertyByOrder = (orderNumber, handleError) => (dispatch) => {
  dispatch(getPropertyByOrderStart());
  axios
    .get(`${API_URL}/orders/${orderNumber}/confirm/`)
    .then((res) => {
      if (res.status === 200) {
        const { data } = res;
        const { pxn_prop_id, email_address, amount, broker, discount_code } = data;
        axios.get(`${API_URL}/properties/${pxn_prop_id}/`).then((res) => {
          if (res.status === 200) {
            const { data } = res;
            dispatch(getPropertyByOrderSuccess(data, email_address || "", orderNumber, amount, broker, discount_code));
          } else {
            throw new Error("No property");
          }
        });
      }
    })
    .catch((err) => {
      handleError && handleError();
      dispatch(getPropertyByOrderError(err));
    });
};

export const getPropertyById = (propertyId, handleError) => (dispatch, getState) => {
  const { freeAssesment } = getState();

  dispatch(getPropertyByOrderStart());
  axios
    .get(`${API_URL}/properties/${propertyId}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getPropertyByOrderSuccess(res.data, freeAssesment.customerEmail || "", null, null, null));
      }
    })
    .catch((err) => {
      handleError();
      dispatch(getPropertyByOrderError(err));
    });
};

export const getPropertyByOrderStart = () => ({
  type: GET_PROPERTY_BY_ORDER,
});

export const getPropertyByOrderSuccess = (property, email, uuid, price, broker, discountCode) => ({
  type: GET_PROPERTY_BY_ORDER_SUCCESS,
  payload: {
    property,
    email,
    uuid,
    price,
    broker,
    discountCode,
  },
});

export const getPropertyByOrderError = (err) => ({
  type: GET_PROPERTY_BY_ORDER_FAIL,
  payload: {
    error: err,
  },
});

export const getPropertyCurrentAssessment = () => (dispatch, getState) => {
  const { freeAssesment } = getState();
  const { selectedAddress } = freeAssesment;

  dispatch({ type: GET_PROPERTY_CURRENT_ASSESSMENT });

  axios
    .get(`${selectedAddress.url}current_assessment/`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getPropertyCurrentAssessmentSuccess(res.data));
      }
    })
    .catch((err) => {
      dispatch(getPropertyCurrentAssessmentFail());
    });
};

export const getPropertyCurrentAssessmentFail = () => ({
  type: GET_PROPERTY_CURRENT_ASSESSMENT_FAIL,
});

export const getPropertyCurrentAssessmentSuccess = (payload) => ({
  type: GET_PROPERTY_CURRENT_ASSESSMENT_SUCCESS,
  payload,
});

export const getBrokerByReferral = (referral) => (dispatch) => {
  dispatch({ type: GET_BROKER_BY_REFERRAL });

  axios
    .get(`${API_URL}/brokers/${referral}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getBrokerByReferralSuccess(res.data));
      }
    })
    .catch(() => {
      dispatch(getBrokerByReferralFail());
    });
};

export const getBrokerByReferralFail = () => ({
  type: GET_BROKER_BY_REFERRAL_FAIL,
});

export const getBrokerByReferralSuccess = (payload) => ({
  type: GET_BROKER_BY_REFERRAL_SUCCESS,
  payload,
});
