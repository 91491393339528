// vendor
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import classNames from "classnames";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

// app
import MainNavbar from "../../components/Navbar/Navbar";
import FreeAssesmentHeader from "../../components/Header/FreeAssesmentHeader";
import MainFooter from "../../components/Footers/Footer";
import LandingPageSection from "../../components/Sections/LandingPageSection";
import FormCard from "../../components/Cards/FormCard";
import { getDiscountCode, getPropertyByOrder } from "../../redux/actions/assessment";
import AssessmentCodeModal, { CodeHere } from "../../components/Modals/AssessmentCodeModal";
import { getDiscountedPrice } from "../../utils";
import { reportPlanSelection } from "../../constants/descriptions";
import { ReportContainer } from "./styled";
import { FormButton } from "../../components/Buttons";

export const ReportStep = {
  ESTIMATE: 0,
  PAYMENT_SUCCESSFUL: 1,
  SELECT_REPORT_TYPE: 2,
};

export const ReportPlanType = {
  ONE_TIME: "One-time",
  YEARLY: "Yearly",
};

const SelectReportPage = () => {
  const [isCodeModalOpen, setCodeModalOpen] = useState(false);
  const [discountObject, setDiscountObject] = useState({});
  const [selectedType, selectType] = useState(ReportPlanType.YEARLY);

  const { orderUuid } = useParams();
  const { globalLoading, error, orderBroker, orderDiscountCode } = useSelector((state) => state.freeAssesment);
  const dispatch = useDispatch();
  const history = useHistory();

  const { title, options, nittyGritty } = reportPlanSelection;
  const validCoupon = discountObject && discountObject.discountPercentage;
  const discount = discountObject?.discountPercentage || (orderBroker?.id && 10) || 0;
  const isFreeReport = discount >= 100;

  const handleSelectReport = () => {
    // if it's free report, redirect to payment confirmation page directly
    if (isFreeReport) {
      history.push("/preorder-complete");
      return;
    }

    // otherwise redirect to payment depending on selection
    if (selectedType === ReportPlanType.ONE_TIME) {
      history.push(`/checkout/${orderUuid}`);
      return;
    }

    history.push(`/subscribe/${orderUuid}`);
  };

  const handleToggleCodeModal = () => {
    setCodeModalOpen((prev) => !prev);
  };

  const onSubmitCode = (discount) => {
    setDiscountObject(discount);
  };

  useEffect(() => {
    if (!orderUuid) history.push("/verify");
    dispatch(getPropertyByOrder(orderUuid));
  }, [orderUuid, dispatch, history]);

  useEffect(() => {
    if (error) {
      history.push("/verify");
    }
  }, [error, history]);

  useEffect(() => {
    const fetchAttachedDiscountCode = async () => {
      try {
        const { data } = await getDiscountCode(orderDiscountCode)
        const discountObject = {
          value: orderDiscountCode,
          discountPercentage: data.discount_percentage,
        };
        setDiscountObject(discountObject)

      } catch (err) {
        console.error('Invalid attached code: ', err)
      }
    }
    
    // only validate discount code if there is no discount already applied to current state and there's a code attached to the order.
    if (orderDiscountCode && !discountObject.discountPercentage && orderUuid) {
      fetchAttachedDiscountCode()
    }
  }, [orderDiscountCode])

  const renderContent = () => {
    if (globalLoading) return <Spinner color="info" />;

    return (
      <Container>
        <Row>
          <Col className="mx-auto" lg="8" md="8" sm="10" xs="12">
            <FormCard>
              <>
                <h2 className="title">
                  <b>{title}</b>
                </h2>
                <Container>
                  <Row>
                    {options.map((option) => (
                      <ReportContainer
                        onClick={() => selectType(option.name)}
                        bgcolor={option.style.background}
                        lg={6}
                        sm={12}
                        key={option.name}
                        selected={selectedType === option.name}
                      >
                        <div className="step_title">{option.name}</div>
                        <div className="price_title">
                          <div
                            className={classNames({
                              discounted: !!discount,
                            })}
                          >
                            ${option.price}
                          </div>
                          {discount > 0 && (
                            <div>{isFreeReport ? "Free" : <>${getDiscountedPrice(discount, option.price)}</>}</div>
                          )}
                        </div>

                        <ul>
                          {option.features.map((f) => (
                            <li key={f}>{f}</li>
                          ))}
                        </ul>
                      </ReportContainer>
                    ))}
                  </Row>
                  <div className="text-center mt-3">
                    <small className="mt-2">* {nittyGritty}</small>
                  </div>

                  {orderBroker && (
                    <div className="text-center mt-3">
                      <i className="fa fa-tags" aria-hidden="true" />
                      Your report this year is reduced in price thanks to <b>
                        {startCase(toLower(orderBroker.name))}
                      </b>{" "}
                      .
                    </div>
                  )}

                  {validCoupon && (
                    <div className="text-center mt-3">
                      <i className="fa fa-tags" aria-hidden="true" />
                      Discount <b>{discountObject.value}</b> applied! (<b>{discountObject.discountPercentage}%</b>{" "}
                      discount)
                    </div>
                  )}
                  <Row>
                    <FormButton className="btn-round mb-2" color="neutral" onClick={handleSelectReport}>
                      Next <i className="fa fa-chevron-right" />
                    </FormButton>
                  </Row>
                </Container>
              </>
              <CodeHere onClick={handleToggleCodeModal}>Have a pre-order or coupon code?</CodeHere>
            </FormCard>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <MainNavbar whiteLogo />
      <FreeAssesmentHeader />
      <LandingPageSection>
        {renderContent()}
        <AssessmentCodeModal
          toggle={handleToggleCodeModal}
          isOpen={isCodeModalOpen}
          submitCode={onSubmitCode}
          orderId={orderUuid}
        />
      </LandingPageSection>
      <MainFooter />
    </>
  );
};

export default SelectReportPage;
