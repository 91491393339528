// vendor
import React from "react";

import { AboutHeaderContainer, HeaderTitle } from "./styled";
import { Container } from "reactstrap";

function FreeAssesmentHeader({ image }) {
  return (
    <AboutHeaderContainer
      className="page-header"
      data-parallax={true}
      image={image || require("../../assets/img/landing-page-image-2.jpg")}
    >
      <div className="filter" />
      <Container>
        <div className="motto">
          <HeaderTitle>About Us</HeaderTitle>
        </div>
      </Container>
    </AboutHeaderContainer>
  );
}

export default FreeAssesmentHeader;
