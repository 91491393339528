import React from "react";
import LandingPageSection from "../../components/Sections/LandingPageSection";
import TestimonialsPanel from "../../components/TestimonialsPanel";
import {
  BLACK_FONT_COLOR,
  LIGHT_GREEN_COLOR,
} from "../../constants/colors";
import { landingPage } from "../../constants/descriptions";

const TestimonialsSection = () => {
  const { testimonialsSection } = landingPage;
  return (
    <div className="section">
      <LandingPageSection
        sectionStyles={{ padding: "1.5rem 0" }}
        titleText={testimonialsSection.title}
        titleStyles={{ color: BLACK_FONT_COLOR }}
        bgColor={LIGHT_GREEN_COLOR}
      />
      <TestimonialsPanel />
    </div>
  );
};

export default TestimonialsSection;
