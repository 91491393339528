import React from "react";
import styled from "styled-components";
import { Container, Row, Spinner, Col, Jumbotron } from "reactstrap";
import {
  BLUE_BACKGROUND_COLOR,
  SALMON_BACKGROUND_COLOR,
} from "../../constants/colors";

const LoaderContainer = styled(Container)`
  height: 30vh;
  .row {
    margin: auto 0;
  }
  padding: 0;
`;

export const JumboContainer = styled(Jumbotron)`
  background-color: ${BLUE_BACKGROUND_COLOR};
  h3 {
    color #fff;
    font-weight: 400;
    text-align: center;
  }

  h5, h4 {
    color #fff;
    font-weight: 400;
    text-align: center;
    

    @media (min-width: 768px) { 
      padding: 0;
    }

    @media (min-width: 992px) { 
      padding: 0 5rem;
    }

    @media (min-width: 1200px) {
      padding: 0 10rem;
    }
  }

  a {
    color: ${SALMON_BACKGROUND_COLOR}
  }

  .spinner-grow {
    margin: 0 auto;
  }

  .col {
    text-align: center;
  }
`;

const PageLoader = ({ text }) => (
  <LoaderContainer>
    <JumboContainer fluid color={BLUE_BACKGROUND_COLOR}>
      <Row className="align-self-center">
        <Col>
          <h3 className="title">{text}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spinner type="grow" color="light" />
        </Col>
      </Row>
    </JumboContainer>
  </LoaderContainer>
);

export default PageLoader;
