export const GET_ADDRESS_SUGGESTIONS = "GET_ADDRESS_SUGGESTIONS";
export const GET_ADDRESS_SUGGESTIONS_SUCCESS = "GET_ADDRESS_SUGGESTIONS_SUCCESS";
export const GET_ADDRESS_SUGGESTIONS_FAIL = "GET_ADDRESS_SUGGESTIONS_FAIL";

export const SET_ADDRESS = "SET_ADDRESS";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_ADDRESS_MODIFICATION = "SET_ADDRESS_MODIFICATION";

export const GET_PROPERTY_BY_ORDER = "GET_PROPERTY_BY_ORDER";
export const GET_PROPERTY_BY_ORDER_SUCCESS = "GET_PROPERTY_BY_ORDER_SUCCESS";
export const GET_PROPERTY_BY_ORDER_FAIL = "GET_PROPERTY_BY_ORDER_FAIL";

export const POST_ASSESSMENT_DATA = "POST_ASSESSMENT_DATA";
export const POST_ASSESSMENT_DATA_SUCCESS = "POST_ASSESSMENT_DATA_SUCCESS";
export const POST_ASSESSMENT_DATA_FAIL = "POST_ASSESSMENT_DATA_FAIL";

export const GET_PROPERTY_CURRENT_ASSESSMENT = "GET_PROPERTY_CURRENT_ASSESSMENT";
export const GET_PROPERTY_CURRENT_ASSESSMENT_SUCCESS = "GET_PROPERTY_CURRENT_ASSESSMENT_SUCCESS";
export const GET_PROPERTY_CURRENT_ASSESSMENT_FAIL = "GET_PROPERTY_CURRENT_ASSESSMENT_FAIL";

export const APPLY_CODE_TO_ORDER = "APPLY_CODE_TO_ORDER";
export const APPLY_CODE_TO_ORDER_SUCCESS = "APPLY_CODE_TO_ORDER_SUCCESS";
export const APPLY_CODE_TO_ORDER_FAIL = "APPLY_CODE_TO_ORDER_FAIL";

export const TOGGLE_UNBOUNCE_MODAL = "TOGGLE_UNBOUNCE_MODAL";
export const SUBMIT_UNBOUNCE = "SUBMIT_UNBOUNCE";
export const SUBMIT_UNBOUNCE_SUCCESS = "SUBMIT_UNBOUNCE_SUCCESS";
export const SUBMIT_UNBOUNCE_FAIL = "SUBMIT_UNBOUNCE_FAIL";

export const GET_BROKER_BY_REFERRAL = "GET_BROKER_BY_REFERRAL";
export const GET_BROKER_BY_REFERRAL_SUCCESS = "GET_BROKER_BY_REFERRAL_SUCCESS";
export const GET_BROKER_BY_REFERRAL_FAIL = "GET_BROKER_BY_REFERRAL_FAIL";
