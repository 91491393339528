import React from "react";
import { preOrderFlow } from "../../constants/descriptions";
import { PaymentSuccessfulContainer } from "./styled";

const PaymentSuccessful = () => {
  return (
    <PaymentSuccessfulContainer>
      <div className="title">
        <h4>
          <b>{preOrderFlow.successPayment.title}</b>
        </h4>
      </div>
      {preOrderFlow.successPayment.paragraphs.map((p, i) => (
        <div key={i}>{p}</div>
      ))}
    </PaymentSuccessfulContainer>
  );
};

export default PaymentSuccessful;
