export const API_URL = "https://api.pxnres.com";
export const DEMO_REPORT_URL = "https://download.protestingpropertytaxes.com/demo_report.pdf";
export const BLOG_URL = "https://resources.protestingpropertytaxes.com/";

export const SUBSCRIPTION_PRICE = 99;
export const ONE_TIME_PRICE = 179;

export const FREE_ASSESSMENT_PROPERTY_PRICE = 200000;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const TAX_YEAR = process.env.REACT_APP_TAX_YEAR;
export const UNBOUNCE_LOCALSTORAGE_KEY = "unbounce_submitted";
export const UNBOUNCE_SUBMITED_TIMESTAMP = "unbounce_submitted_timestamp";
export const ASSESSES_AFTER = process.env.REACT_APP_LAST_VALID_APPRAISAL_DATE;
export const EIGHT_HOURS_MS = "28800000";
export const BROKER_REFERRAL_SESSION_STORAGE_KEY = "broker_referral_code";
