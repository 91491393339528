import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";

import {
  SALMON_BACKGROUND_COLOR,
  BLACK_FONT_COLOR,
  BLUE_BACKGROUND_COLOR,
  LIGHT_GRAY_BACKGROUND,
} from "../../constants/colors";

const ColoredButton = styled(Button)`
  background-color: ${({ usercolor }) => usercolor};
  border-color: ${({ usercolor }) => usercolor};
  color: ${BLACK_FONT_COLOR};

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ usercolor }) => usercolor} !important;
  }
`;

const GoBackButtonStyles = styled.div`
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    font-weight: 600;
  }
`;

export const HeaderButton = ({ children, onClick, usercolor, ...rest }) => (
  <ColoredButton
    {...rest}
    usercolor={usercolor || SALMON_BACKGROUND_COLOR}
    className="btn-round"
    color="neutral"
    type="button"
    onClick={onClick}
  >
    {children}
  </ColoredButton>
);

export const FormButton = styled(Button)`
  background-color: ${BLUE_BACKGROUND_COLOR};
  border-color: ${BLUE_BACKGROUND_COLOR};
  margin: 1.5rem auto;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    border-color: ${BLUE_BACKGROUND_COLOR};
    color: ${BLUE_BACKGROUND_COLOR};
    background-color: ${LIGHT_GRAY_BACKGROUND};
  }
`;

export const GoBackButton = ({ title, ...props }) => (
  <GoBackButtonStyles {...props}>
    <i className="fa fa-chevron-left" /> {title || "Go Back"}
  </GoBackButtonStyles>
);
