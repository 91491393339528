import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import debounce from "lodash/debounce";
import qs from "qs";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/paper-kit.min.css";
import "./index.css";

import LandingPage from "./containers/LandingPage";
import FreeAssesmentPage from "./containers/FreeAssesmentPage";
import FAQPage from "./containers/FAQPage";
import About from "./containers/AboutPage";
import PricingPage from "./containers/PricingPage";
import StripeRedirectPage from "./containers/StripeRedirectPage";
import PaymentSuccess from "./containers/PaymentSuccess";
import SubmitAssessment from "./containers/SubmitAssessment";
import PreOrderEstimate from "./containers/PreOrderEstimate";
import RetrieveReportPage from "./containers/RetrieveReportPage";
import PrivacyPolicyPage from "./containers/PrivacyPolicyPage";
import SelectReportPage from "./containers/SelectReportPage";
import TermsAndConditionsPage from "./containers/TermsAndConditionsPage";
//import UnBounceModal from "./components/Modals/UnBounceModal";
import { toggleUnbounceModal } from "./redux/actions/unBounceModal";
import {
  UNBOUNCE_LOCALSTORAGE_KEY,
  UNBOUNCE_SUBMITED_TIMESTAMP,
  EIGHT_HOURS_MS,
  BROKER_REFERRAL_SESSION_STORAGE_KEY,
} from "./constants";
import { getBrokerByReferral } from "./redux/actions/assessment";

class App extends React.Component {
  state = {
    mustShowUnbounceModal: false,
  };

  static getDerivedStateFromProps({ isUnbounceModalToggled, isModalSubmitted }) {
    const submittedTimestamp = localStorage.getItem(UNBOUNCE_SUBMITED_TIMESTAMP);

    if (submittedTimestamp) {
      if (new Date().getTime() - submittedTimestamp >= EIGHT_HOURS_MS) {
        localStorage.removeItem(UNBOUNCE_LOCALSTORAGE_KEY);
        localStorage.removeItem(UNBOUNCE_SUBMITED_TIMESTAMP);
      }
    }

    const isSubmitted = localStorage.getItem(UNBOUNCE_LOCALSTORAGE_KEY);
    const mustShowUnbounceModal = !isUnbounceModalToggled && !isSubmitted && !isModalSubmitted;

    return {
      mustShowUnbounceModal,
    };
  }

  toggleModal = debounce(() => {
    this.props.toggleUnbounceModal();
  }, 500);

  componentDidMount() {
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const { referral } = params;
    const storedReferralCode = sessionStorage.getItem(BROKER_REFERRAL_SESSION_STORAGE_KEY);

    if (referral) {
      window.location.href = window.location.origin + window.location.pathname;
      sessionStorage.setItem(BROKER_REFERRAL_SESSION_STORAGE_KEY, referral);
    }

    if (storedReferralCode) {
      this.props.getBrokerByReferral(storedReferralCode);
    }
  }

  render() {
    const { mustShowUnbounceModal } = this.state;

    return (
      <div onMouseLeave={mustShowUnbounceModal ? this.toggleModal : null}>
        <Router>
          <Switch>
            <Route path="/pre-order/submit" exact>
              <PreOrderEstimate />
            </Route>
            <Route path="/select-report/:orderUuid" exact>
              <SelectReportPage />
            </Route>
            <Route path="/submit">
              <SubmitAssessment />
            </Route>
            <Route path={"/verify"}>
              <FreeAssesmentPage
                isUnbounceModalToggled={this.props.isUnbounceModalToggled}
                isModalSubmitted={this.props.isModalSubmitted}
                toggleUnbounceModal={this.props.toggleUnbounceModal}
              />
            </Route>
            <Route path="/subscribe/:orderUuid" exact>
              <StripeRedirectPage subscribe />
            </Route>
            <Route path="/checkout/:orderUuid" exact>
              <StripeRedirectPage />
            </Route>
            <Route path="/report/:uuid">
              <RetrieveReportPage />
            </Route>
            <Route path={"/faq"}>
              <FAQPage />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/pricing">
              <PricingPage />
            </Route>
            <Route path="/privacy" exact>
              <PrivacyPolicyPage />
            </Route>
            <Route path="/terms-and-conditions" exact>
              <TermsAndConditionsPage />
            </Route>
            <Route path="/success">
              <PaymentSuccess />
            </Route>
            <Route path="/preorder-complete">
              <PreOrderEstimate preorderComplete />
            </Route>
            <Route path="/" exact>
              <LandingPage />
            </Route>
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
        {/*
        <UnBounceModal
          isOpen={this.props.isUnbounceModalToggled}
          toggle={this.props.toggleUnbounceModal}
        />
        */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isUnbounceModalToggled: state.unBounceModal.unbounceModalShow,
  isModalSubmitted: state.unBounceModal.isSubmitted,
});

const mapDispatchToProps = (dispatch) => ({
  toggleUnbounceModal: () => dispatch(toggleUnbounceModal()),
  getBrokerByReferral: (referral) => dispatch(getBrokerByReferral(referral)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
