import { combineReducers } from "redux";
import assessmentReducer from "./assessmentReducer";
import unBounceModalReducer from "./unBounceModalReducer";
import sessionReducer from "./sessionReducer";

export default combineReducers({
  freeAssesment: assessmentReducer,
  unBounceModal: unBounceModalReducer,
  session: sessionReducer,
});
