import React from "react";
import styled from "styled-components";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { SALMON_BACKGROUND_COLOR } from "../../constants/colors";
import { termsAndConditions } from "../../constants/termsAndConditions";

const Body = styled(ModalBody)`
  p {
    margin-bottom: 2rem;
  }
`;

function TermsAndConditionsModal({ isOpen, toggle, ...rest }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} {...rest} size="lg">
      <ModalHeader toggle={toggle}>Terms And Conditions</ModalHeader>
      <Body>{termsAndConditions}</Body>
    </Modal>
  );
}

export const ModalTogglerStyled = styled.span`
  color: ${SALMON_BACKGROUND_COLOR};
  cursor: pointer;
  font-weight: 500;
`;

export default TermsAndConditionsModal;
