import React from "react";
import MailCheck from "react-mailcheck";
import PropTypes from "prop-types";
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
} from "reactstrap";
import { FormError } from "../Cards/FormCard";

const MailCheckInput = ({
  inputText,
  handleEmailChange,
  children,
  useInputAddon,
}) => (
  <MailCheck
    email={inputText}
    domains={[
      "yahoo.com",
      "gmail.com",
      "hotmail.com",
      "comcast.net",
      "att.net",
      "aol.com",
      "outlook.com",
    ]}
    topLevelDomains={["com", "net"]}
  >
    {(suggestion) => (
      <>
        {useInputAddon ? (
          <FormGroup>
            <InputGroup className="form-group-no-border">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-email-85" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Email"
                type="email"
                onChange={handleEmailChange}
                value={inputText}
              />
            </InputGroup>
            {suggestion && (
              <FormError fontWeight={500}>
                Did you mean {suggestion.full}?
              </FormError>
            )}
            {children}
          </FormGroup>
        ) : (
          <FormGroup className="form-group-no-border">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                Email
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Email"
              type="email"
              onChange={handleEmailChange}
              value={inputText}
            />
            {suggestion && (
              <FormError fontWeight={500}>
                Did you mean {suggestion.full}?
              </FormError>
            )}
            {children}
          </FormGroup>
        )}
      </>
    )}
  </MailCheck>
);

MailCheckInput.propTypes = {
  inputText: PropTypes.string,
  handleEmailChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  useInputAddon: PropTypes.bool,
};

MailCheckInput.defaultProps = {
  useInputAddon: true,
};

export default MailCheckInput;
