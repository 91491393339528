import {
  GET_ADDRESS_SUGGESTIONS,
  GET_ADDRESS_SUGGESTIONS_SUCCESS,
  SET_ADDRESS,
  SET_USER_EMAIL,
  SET_ADDRESS_MODIFICATION,
  GET_PROPERTY_BY_ORDER_SUCCESS,
  GET_PROPERTY_BY_ORDER,
  POST_ASSESSMENT_DATA_SUCCESS,
  POST_ASSESSMENT_DATA,
  POST_ASSESSMENT_DATA_FAIL,
  GET_PROPERTY_BY_ORDER_FAIL,
  GET_PROPERTY_CURRENT_ASSESSMENT,
  GET_PROPERTY_CURRENT_ASSESSMENT_SUCCESS,
  GET_PROPERTY_CURRENT_ASSESSMENT_FAIL,
} from "../actionTypes";

const assessmentInitialState = {
  freeAssesmentFormValues: {},
  addressSuggestions: [],
  error: null,
  loading: {
    addressSuggestions: false,
  },
  selectedAddress: {},
  customerEmail: "",
  globalLoading: false,
  uuid: null,
  orderPrice: null,
  orderBroker: null,
  orderDiscountCode: null,
};

const assessmentReducer = (state = assessmentInitialState, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUGGESTIONS:
      return {
        ...state,
        loading: {
          ...state.loading,
          addressSuggestions: true,
        },
      };
    case GET_ADDRESS_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        addressSuggestions: action.payload.items,
        loading: {
          ...state.loading,
          addressSuggestions: false,
        },
        error: null,
      };
    case SET_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload.address,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        customerEmail: action.payload.email,
      };
    case SET_ADDRESS_MODIFICATION:
      return {
        ...state,
        freeAssesmentFormValues: action.payload.values,
      };
    case GET_PROPERTY_BY_ORDER:
      return {
        ...state,
        globalLoading: true,
      };
    case GET_PROPERTY_BY_ORDER_SUCCESS:
      return {
        ...state,
        globalLoading: false,
        selectedAddress: action.payload.property,
        error: null,
        customerEmail: action.payload.email,
        uuid: action.payload.uuid,
        orderPrice: action.payload.price,
        orderBroker: action.payload.broker,
        orderDiscountCode: action.payload.discountCode,
      };
    case GET_PROPERTY_BY_ORDER_FAIL:
      return {
        ...state,
        error: action.payload.error,
        globalLoading: false,
      };
    case POST_ASSESSMENT_DATA:
      return {
        ...state,
        globalLoading: true,
      };
    case POST_ASSESSMENT_DATA_SUCCESS:
      const { isPreOrder, response } = action.payload;
      if (isPreOrder) {
        return {
          ...state,
          orderComputationResponse: { ...response.data },
          globalLoading: false,
        };
      }
      return {
        ...assessmentInitialState,
      };
    case POST_ASSESSMENT_DATA_FAIL:
      return {
        ...assessmentInitialState,
      };
    case GET_PROPERTY_CURRENT_ASSESSMENT:
      return {
        ...state,
        globalLoading: true,
        error: null,
      };
    case GET_PROPERTY_CURRENT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          appraisedData: action.payload,
        },
        globalLoading: false,
      };
    case GET_PROPERTY_CURRENT_ASSESSMENT_FAIL:
      return {
        ...state,
        globalLoading: false,
      };
    default:
      return state;
  }
};

export default assessmentReducer;
