import React from "react";

export const privacyPolicy = (
  <>
    <p>
      We at ProtestingPropertyTaxes.com (the "Company") are committed to protecting your personal information and your
      right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your
      personal information, please contact us at privacy-rights@protestingpropertytaxes.com.
    </p>
    <br />
    <p>
      {" "}
      When you visit our website and use our appraisal or property tax contestation services (our "Services"), you trust
      us with your personal information. We take your privacy seriously. In this privacy policy, we aim explain to you
      clearly what information we collect, how we use it and what rights you have in relation to it. We hope you take
      some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you
      do not agree with, please discontinue use of our website and Services.
    </p>
    <br />
    <p>
      Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal
      information with us.
    </p>
    <br /> <br />
    <p>1. WHAT INFORMATION DO WE COLLECT?</p>
    <br />
    <p>
      Personal information you disclose to us: We collect certain personal information that you provide to us. This
      contact information includes your name, address, contact information, and information related to your home,
      including its ownership. This information is provided or verified voluntarily by you in order to make accurate use
      of our Services.
    </p>
    <br />
    <p>
      Publicly available personal information: We may collect names and contact information from publicly available
      records. We may use this information to market to you.
    </p>
    <br />
    <p>
      Payment Data. All payment data is stored by Stripe. You may find their privacy policy link here:
      https://stripe.com/privacy.
    </p>
    <br />
    <p>
      Information automatically collected: We automatically collect certain information when you visit our website. This
      information does not reveal your specific identity (like your name or contact information) but may include device
      and usage information, such as your IP address, browser and device characteristics, operating system, language
      preferences, referring URLs, device name, country, location, information about how and when you use our Services
      and other technical information. This information is primarily needed to maintain the security and operation of
      our Services, and for our internal analytics and reporting purposes. Like many businesses, we also collect
      information through cookies and similar technologies.
    </p>
    <br />
    <br />
    <p>2. HOW DO WE USE YOUR INFORMATION?</p>
    <br />
    <p>
      We use personal information collected via our Services for a variety of business purposes. We process your
      personal information for these purposes in reliance on our legitimate business interests, in order to enter into
      or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate
      the specific processing grounds we rely on next to each purpose listed below.
    </p>
    <br />
    <p>We use the information we collect or receive:</p>
    <br />
    <p>
      To send you marketing and promotional communications. We and/or our third party marketing partners may use the
      personal information you send to us for our marketing purposes, if this is in accordance with your marketing
      preferences. You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
    </p>
    <p>
      To send administrative information to you. We may use your personal information to send you product, service and
      new feature information and/or information about changes to our terms, conditions, and policies.
    </p>
    <p>
      Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns,
      and exchanges made through the Services.
    </p>
    <p>
      To post testimonials. We post testimonials on our Services that may contain personal information. Prior to posting
      a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your
      testimonial, please contact us at help@protestingpropertytaxes.com.
    </p>
    <p>
      Deliver targeted advertising to you. We may use your information to develop and display content and advertising
      (and work with third parties who do so) tailored to your interests and/or location and to measure its
      effectiveness.
    </p>
    <p>
      Request Feedback. We may use your information to request feedback and to contact you about your use of our
      Services.
    </p>
    <p>
      To protect our Services. We may use your information as part of our efforts to keep our Services safe and secure
      (for example, for fraud monitoring and prevention).
    </p>
    <p>To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and Contractual.</p>
    <p>
      To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to
      inspect the data we hold to determine how to respond.
    </p>
    <p>To deliver services to the user. We may use your information to provide you with the requested service.</p>
    <p>
      To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and
      solve any potential issues you might have with the use of our Services.
    </p>
    <p>
      For other Business Purposes. We may use your information for other Business Purposes, such as data analysis,
      identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve
      our Services, products, marketing and your experience. We may use and store this information in aggregated and
      anonymized form so that it is not associated with individual end users and does not include personal information.
      We will not use identifiable personal information without your consent.
    </p>
    <br /> <br />
    <p>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
    <br />
    <p>
      We only share information with your consent, to comply with laws, to provide you with services, to protect your
      rights, or to fulfill business obligations. We may process or share data based on the following legal basis:
    </p>
    <br />
    <p>
      Consent: We may process your data if you have given us specific consent to use your personal information in a
      specific purpose.
    </p>
    <p>
      Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business
      interests.
    </p>
    <p>
      Performance of a Contract: Where we have entered into a contract with you, we may process your personal
      information to fulfill the terms of our contract.
    </p>
    <p>
      Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with
      applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response
      to a court order or a subpoena (including in response to public authorities to meet national security or law
      enforcement requirements).
    </p>
    <p>
      Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or
      take action regarding potential violations of our policies, suspected fraud, situations involving potential
      threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
    </p>
    <p>
      More specifically, we may need to process your data or share your personal information in the following
      situations:
    </p>
    <br />
    <p>
      Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors,
      service providers, contractors or agents who perform services for us or on our behalf and require access to such
      information to do that work. Examples include: payment processing, data analysis, email delivery, hosting
      services, customer service and marketing efforts. We may allow selected third parties to use tracking technology
      on the Services, which will enable them to collect data about how you interact with the Services over time. This
      information may be used to, among other things, analyze and track data, determine the popularity of certain
      content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or
      trade any of your information with third parties for their promotional purposes.
    </p>
    <p>
      Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any
      merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
    </p>
    <p>
      Third-Party Advertisers. We may use third-party advertising companies to serve ads when you visit the Services.
      These companies may use information about your visits to our Website(s) and other websites that are contained in
      web cookies and other tracking technologies in order to provide advertisements about goods and services of
      interest to you.
    </p>
    <br /> <br />
    <p>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
    <br />
    <p>
      We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information
      about how you use our site. We use these analytics to improve your experience and the experience of all our users.
    </p>
    <br /> <br />
    <p>5. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
    <br />
    <p>
      We will only keep your personal information for as long as it is necessary for the purposes set out in this
      privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other
      legal requirements). No purpose in this policy will require us keeping your personal information for longer than 6
      months past the termination of the user's account.
    </p>
    <br />
    <p>
      When we have no ongoing legitimate business need to process your personal information, we will either delete or
      anonymize it, or, if this is not possible (for example, because your personal information has been stored in
      backup archives), then we will securely store your personal information and isolate it from any further processing
      until deletion is possible.
    </p>
    <br /> <br />
    <p>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
    <br />
    <p>
      We have implemented appropriate technical and organizational security measures designed to protect the security of
      any personal information we process. However, please also remember that we cannot guarantee that the Internet
      itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal
      information to and from our Services is at your own risk. You should only access the services within a secure
      environment.
    </p>
    <br /> <br />
    <p>7. DO WE COLLECT INFORMATION FROM MINORS?</p>
    <br />
    <p>
      We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you
      represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
      minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age
      has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from
      our records. If you become aware of any data we have collected from children under age 18, please contact us at
      privacy-rights@protestingpropertytaxes.com.
    </p>
    <br /> <br />
    <p>8. EMAIL MARKETING</p>
    <br />
    <p>
      You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails
      that we send or by contacting us using the details provided below. You will then be removed from the marketing
      email list – however, we will still need to send you service-related emails that are necessary for the
      administration and use of our Services. To otherwise opt-out, you may email us directly at
      help@protestingpropertytaxes.com
    </p>
    <br /> <br />
    <p>9. WHAT ARE YOUR PRIVACY RIGHTS?</p>
    <br />
    <p>
      If you are resident in the European Economic Area and you believe we are unlawfully processing your personal
      information, you also have the right to complain to your local data protection supervisory authority. You can find
      their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
    </p>
    <br />
    <p>
      California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are
      California residents to request and obtain from us, once a year and free of charge, information about categories
      of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
      addresses of all third parties with which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a request, please submit your request in
      writing to us using the contact information provided below.
    </p>
    <br />
    <p>
      If you are under 18 years of age, reside in California, and have a registered account with the Services, you have
      the right to request removal of unwanted data that you publicly post on the Services. To request removal of such
      data, please contact us using the contact information provided below, and include the email address associated
      with your account and a statement that you reside in California. We will make sure the data is not publicly
      displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from
      our systems.
    </p>
    <br />
    <p>
      If you have questions or comments about your privacy rights, you may email us at
      privacy-rights@protestingpropertytaxes.com.
    </p>
    <br />
    <br />
    <p>10. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
    <br />
    <p>
      Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature
      or setting you can activate to signal your privacy preference not to have data about your online browsing
      activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals
      has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that
      automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that
      we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.
    </p>
    <br /> <br />
    <p>11. DO WE MAKE UPDATES TO THIS POLICY?</p>
    <br />
    <p>
      We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised”
      date and the updated version will be effective as soon as it is accessible. If we make material changes to this
      privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending
      you a notification. We encourage you to review this privacy policy frequently to be informed of how we are
      protecting your information.
    </p>
    <br />
    <br />
    <p>12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
    <br />
    <p>
      If you have questions or comments about this policy, you may email us at
      privacy-rights@protestingpropertytaxes.com or by post to:
    </p>
    <br />
    <p>ProtestingPropertyTaxes.com</p>
    <p>c/o Austin Real Estate Partners</p>
    <p>12332 Cardinal Flower Dr</p>
    <p>Austin, TX 78739</p>
    <br />
    <p>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
    <p>
      Based on the laws of some countries, you may have the right to request access to the personal information we
      collect from you, change that information, or delete it in some circumstances. To request to review, update, or
      delete your personal information, please submit a request to privacy-rights@protestingpropertytaxes.com. We will
      respond to your request within 30 days.
    </p>
    <br />
    <br />
  </>
);
