// vendor
import React from "react";
import { Container, Row } from "reactstrap";

// app
import {
  ThirdSectionImage,
  ThirdSectionTitle,
  DescriptionParagraphs,
  DescriptionContainer,
} from "./styled";
import { landingPage } from "../../constants/descriptions";

const ThirdSection = () => {
  const { thirdSection } = landingPage;

  return (
    <div className="section text-center">
      <Container>
        <Row>
          <DescriptionContainer lg="6" md="12" offsetright="true">
            <ThirdSectionTitle className="title">
              {thirdSection.title} &nbsp;
            </ThirdSectionTitle>
            <DescriptionParagraphs>
              {thirdSection.paragraphs.map((p, index) => {
                if (index === 0) {
                  return (
                    <p key={`${p[0]}-index`}>
                      <b>{p}</b>
                    </p>
                  );
                }

                return <p key={`${p[0]}-index`}>{p}</p>;
              })}
            </DescriptionParagraphs>
          </DescriptionContainer>
          <ThirdSectionImage lg="6">
            <img
              src={require("../../assets/img/bill_and_bob_statistics.png")}
              alt={thirdSection.title}
            />
          </ThirdSectionImage>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default ThirdSection;
