import styled from "styled-components";
import { Container, Row } from "reactstrap";

export const ContainerSectionOne = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 90%;
  text-align: center;
  padding: 2.5rem 0;
  font-weight: 400;
  color: #2e2e2e;

  .title_container {
    .title {
      font-size: 36px;
      font-weight: 500;
    }
    margin-bottom: 2rem;
  }

  .prices_step_container {
    margin-top: 2rem;
  }

  .pricing_cta {
    align-self: center;
    margin-top: 2.5rem;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 70%;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 60%;
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    width: 50%;
  }
`;

export const StepContainer = styled(Container)`
  background-color: ${({ bgcolor }) => bgcolor};
  padding: 2rem;
  height: 100%;
  .step_title {
    font-size: 20px;
    font-weight: 500;
  }

  .icon_title {
    font-size: 36px;
    font-weight: 800;
    margin: 1rem 0;
  }

  img {
    width: 80px;
    margin: 1rem 0;
  }
`;

export const SectionTwoWrapper = styled.div`
  background-color: #4e615f;
  color: #ffffff !important;
  text-align: center;
  padding: 2.5rem 0;
`;

export const ContainerSectionTwo = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 90%;

  .title_second_section {
    font-size: 30px;
    font-weight: 500;
    hyphens: auto;
    overflow: hidden;
  }

  .pricing_cta {
    align-self: center;
    margin-top: 2.5rem;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 60%;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 50%;
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    width: 40%;
  }
`;

export const SectionTwoParagraph = styled(Row)`
  img {
    width: 90%;
  }

  margin-top: 1.5rem;
`
