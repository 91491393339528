// app
import React from "react";

//vendor
import LandingPageSection from "../../components/Sections/LandingPageSection";
import {
  BLACK_FONT_COLOR,
  SALMON_BACKGROUND_COLOR,
} from "../../constants/colors";
import { landingPage } from "../../constants/descriptions";

const BottomSection = () => {
  return (
    <LandingPageSection
      titleText={landingPage.finalSection.title}
      titleStyles={{
        color: BLACK_FONT_COLOR,
        width: "60%",
        fontWeight: "400",
      }}
      bgColor={SALMON_BACKGROUND_COLOR}
    >
      <img
        src={require("../../assets/img/solid_logo_text_gray.png")}
        alt={landingPage.finalSection.imageName}
        width="30%"
      />
    </LandingPageSection>
  );
};

export default BottomSection;
