import React, { useState, useEffect } from "react";
import { Container, Row, CardHeader, Collapse, CardBody } from "reactstrap";
import { useLocation } from 'react-router';

// app
import { FAQPageQuestions } from "../../constants/descriptions";
import MainNavbar from "../../components/Navbar/Navbar";
import FreeAssesmentHeader from "../../components/Header/FreeAssesmentHeader";
import LandingPageSection from "../../components/Sections/LandingPageSection";
import MainFooter from "../../components/Footers/Footer";
import FAQCard, { FAQContainer } from "../../components/Cards/FAQCard";

const FAQPage = () => {
  const [collapsed, collapse] = useState(null);
  const location = useLocation();
  const { hash } = location;

  useEffect(() => {
    if (hash) {
      const faq = hash.split('#')[1];
      const element = document.getElementById(faq);
      if (element) {
        element.scrollIntoView();
        collapse(faq);
      }
    }
  }, [hash]);

  return (
    <>
      <MainNavbar whiteLogo />
      <FreeAssesmentHeader />
      <LandingPageSection titleText="Frequently Asked Questions.">
        <Container>
          <Row>
            <FAQContainer lg={12}>
              {FAQPageQuestions.rows.map((q) => (
                <FAQCard key={q.id} id={q.id}>
                  <CardHeader
                    onClick={() => {
                      collapse(collapsed === q.id ? null : q.id)
                      window.history.pushState(null, null, `/faq#${q.id}`)
                    }}
                  >
                    {q.title}
                  </CardHeader>
                  <Collapse isOpen={collapsed === q.id}>
                    <CardBody>
                      <q.content />
                    </CardBody>
                  </Collapse>
                </FAQCard>
              ))}
            </FAQContainer>
          </Row>
        </Container>
      </LandingPageSection>
      <MainFooter />
    </>
  );
};

export default FAQPage;
