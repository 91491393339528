export const SALMON_BACKGROUND_COLOR = "#FFC1A3"; // used for section background colors and button colors
export const BLUE_BACKGROUND_COLOR = "#35435D"; // used for section background colors
export const BLACK_FONT_COLOR = "#333333"; // used for some font-colors
export const LIGHT_GRAY_BACKGROUND = "#f2f2f2"; // used for form section background
export const LIGHT_GREEN_COLOR = '#CCDBD9'; // used for section background colors

export const NUMBER_01_COLOR = "#BFC8D7"; // used for check appraisal 01 section
export const NUMBER_02_COLOR = LIGHT_GREEN_COLOR; // used for check appraisal 02 section
export const NUMBER_03_COLOR = SALMON_BACKGROUND_COLOR; // used for check appraisal 03 section.
export const QUESTION_MARK_COLOR = "#b6b6b6"; // used for check appraisal Question Mark section.
