import React from "react";
import { PriceRangeDisplayContainer } from "./styled";
import { roundToNearestHundreth, toCurrency } from '../../utils';

const PriceRangeDisplay = ({ appraisedValue }) => {
  const firstValue = roundToNearestHundreth(+appraisedValue * 0.08);
  const secondValue = roundToNearestHundreth(+appraisedValue * 0.12);
  
  return (
    <PriceRangeDisplayContainer>
      {toCurrency(firstValue)} - {toCurrency(secondValue)}
    </PriceRangeDisplayContainer>
  );
};

export default PriceRangeDisplay;
