import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Card } from "reactstrap";
import {
  BLUE_BACKGROUND_COLOR,
  LIGHT_GRAY_BACKGROUND,
} from "../../constants/colors";

const CardForm = styled(Card)`
  background-color: ${LIGHT_GRAY_BACKGROUND};
  border-radius: 8px;
  padding: 1.875rem;
  color: #000;

  label {
    margin-top: 15px;
  }

  .card-title {
    color: #b33c12;
    text-align: center;
  }

  .btn {
    margin-top: 1.375rem;
    background-color: ${BLUE_BACKGROUND_COLOR};
    color: #fff;

    &:hover {
      border-color: ${BLUE_BACKGROUND_COLOR};
      color: ${BLUE_BACKGROUND_COLOR};
      background-color: ${LIGHT_GRAY_BACKGROUND};
    }
  }

  .input-group-text {
    border: 1px solid #ffffff;
    background-color: ${LIGHT_GRAY_BACKGROUND};
    border-color: ${LIGHT_GRAY_BACKGROUND};
  }

  .input-group {
    margin-bottom: 0.5rem;
  }

  &:hover {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15) !important;
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    -moz-transform: none !important;
  }
`;

const FormCard = ({ children }) => <CardForm>{children}</CardForm>;

export const FormError = styled.label`
  text-align: left;
  color: red;
  display: block;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

FormCard.propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
  bgNumber: PropTypes.object,
  title: PropTypes.string,
};

export default FormCard;
