import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BLUE_BACKGROUND_COLOR } from "../../constants/colors";

export const BANNER_HEIGHT = "3rem";

export const BannerLink = styled(Link)`
  text-decoration: underline;
  color: white;
  font-weight: bold;

  &:focus,
  &:hover,
  &:link,
  &:active {
    text-decoration: underline;
  }
`;

const StyledBanner = styled.div`
  background-color: ${BLUE_BACKGROUND_COLOR};
  width: 100%;
  position: sticky;
  top: 0;
  color: white;
  text-align: center;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  > h5 {
    font-weight: 400;
  }
`;

const Banner = ({ children }) => {
  return <StyledBanner>{children}</StyledBanner>;
};

export default Banner;
