import styled from "styled-components";

export const InstructionListContainer = styled.ul`
  text-align: left;
`;

export const PaymentSuccessfulContainer = styled.div`
  padding: 0;
  text-align: left;

  p {
    margin-bottom: 1rem;
  }
`;

export const CodeAppliedText = styled.i`
  font-weight: bold;
  color: red;
`;
