// vendor
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import isEmpty from "lodash/isEmpty";

// app
import MainNavbar from "../../components/Navbar/Navbar";
import FreeAssesmentHeader from "../../components/Header/FreeAssesmentHeader";
import MainFooter from "../../components/Footers/Footer";
import LandingPageSection from "../../components/Sections/LandingPageSection";
import FormCard from "../../components/Cards/FormCard";
import EstimateValue from "./EstimateValue";
import PaymentSuccessful from "./PaymentSuccessful";
import { postAssessmentData, getPropertyCurrentAssessment } from "../../redux/actions/assessment";

export const ReportStep = {
  ESTIMATE: 0,
  PAYMENT_SUCCESSFUL: 1,
};

const PreOrderEstimate = ({
  selectedAddress,
  history,
  postAssessmentData,
  uuid,
  customerEmail,
  globalLoading,
  preorderComplete,
  forcedState,
}) => {
  const [assessmentStep, setAssessmentStep] = useState(
    forcedState ? forcedState : preorderComplete ? ReportStep.PAYMENT_SUCCESSFUL : ReportStep.ESTIMATE
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(selectedAddress) && !preorderComplete) {
      history.push("/verify");
      return;
    }
    if (!preorderComplete) {
      getPropertyAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPropertyAssessment = () => {
    dispatch(getPropertyCurrentAssessment());
  };

  const handleChangeStep = (step) => {
    setAssessmentStep(step);
  };

  const renderStep = () => {
    const commonProps = {
      onSubmit: handleChangeStep,
      selectedAddress,
      postAssessmentData,
      uuid,
      customerEmail: customerEmail,
    };

    switch (assessmentStep) {
      case ReportStep.ESTIMATE:
        return <EstimateValue {...commonProps} />;
      case ReportStep.PAYMENT_SUCCESSFUL:
        return <PaymentSuccessful />;
      default:
        break;
    }
  };

  return (
    <>
      <MainNavbar whiteLogo />
      <FreeAssesmentHeader />
      <LandingPageSection>
        {globalLoading ? (
          <Spinner color="info" />
        ) : (
          <Container>
            <Row>
              <Col className="mx-auto" lg="6" md="8" sm="10" xs="12">
                <FormCard>{renderStep()}</FormCard>
              </Col>
            </Row>
          </Container>
        )}
      </LandingPageSection>
      <MainFooter />
    </>
  );
};

const mapStateToProps = (state) => ({
  addressSuggestions: state.freeAssesment.addressSuggestions,
  selectedAddress: state.freeAssesment.selectedAddress,
  customerEmail: state.freeAssesment.customerEmail,
  globalLoading: state.freeAssesment.globalLoading,
  uuid: state.freeAssesment?.orderComputationResponse?.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  postAssessmentData: (discountObject, isFree = false) => dispatch(postAssessmentData(discountObject, isFree)),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRedux, withRouter)(PreOrderEstimate);
