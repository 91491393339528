import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
} from "reactstrap";

import { FormError } from "../Cards/FormCard";
import TermsAndConditionsInput from "../Inputs/TermsAndConditions";
import { formatAddress, titleCase } from "../../utils";

const VerifyAddressForm = ({
  values,
  selectedAddress,
  errors,
  handleChange,
  handleCertifyInformationChange,
  isInformationCertified,
  onChangeCity,
  handleSubmit,
  disableConfirmation,
  disableAll,
  submitButtonText,
  termsAndConditions,
  handleChangeTermsAndConditions,
  submitButtonDisabled,
  handleToggleTermsAndConditionsModal,
  AdditionalFields,
}) => {
  const { bedCount, bathCount, buildingSqFt, lotSqFt, yearBuilt, city } =
    values;

  return (
    <Form className="register-form">
      <FormGroup className="form-group-no-border">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Address</InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder="...."
          type="address"
          value={formatAddress(selectedAddress)}
          disabled
        />
      </FormGroup>

      <Row form>
        <Col md={6}>
          <FormGroup className="form-group-no-border">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Bedrooms</InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="#"
              value={bedCount}
              name="bedCount"
              onChange={handleChange}
              type="number"
              required
              min="1"
              step="1"
              disabled={disableAll}
            />
            {errors.bedCount && <FormError>{errors.bedCount}</FormError>}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="form-group-no-border">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Bathrooms</InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="#"
              value={bathCount}
              name="bathCount"
              onChange={handleChange}
              type="number"
              required
              min="1"
              disabled={disableAll}
            />
            {errors.bathCount && <FormError>{errors.bathCount}</FormError>}
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup className="form-group-no-border">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Sq. Ft </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="#"
              value={buildingSqFt}
              name="buildingSqFt"
              onChange={handleChange}
              type="number"
              required
              min="1"
              disabled={disableAll}
            />
            {errors.buildingSqFt && (
              <FormError>{errors.buildingSqFt}</FormError>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="form-group-no-border">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Lot Size (Sq. Ft.)</InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="#"
              value={lotSqFt}
              name="lotSqFt"
              onChange={handleChange}
              type="number"
              required
              min="0"
              disabled={disableAll || lotSqFt === 0}
              id="LotSizeTooltip"
              style={lotSqFt === 0 ? { cursor: "pointer" } : {}}
            />
            {errors.lotSqFt && <FormError>{errors.lotSqFt}</FormError>}
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="form-group-no-border">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Year Built</InputGroupText>
        </InputGroupAddon>
        <Input
          min={1900}
          max={new Date().getFullYear()}
          placeholder="2018"
          value={yearBuilt}
          name="yearBuilt"
          onChange={handleChange}
          type="number"
          required
          disabled={disableAll}
        />
        {errors.yearBuilt && <FormError>{errors.yearBuilt}</FormError>}
      </FormGroup>
      <FormGroup className="form-group-no-border">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>City</InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder="Austin"
          value={titleCase(city)}
          name="city"
          onChange={onChangeCity}
          type="text"
          required
          disabled={disableAll}
        />
        {errors.city && <FormError>{errors.city}</FormError>}
      </FormGroup>
      {!disableConfirmation && (
        <FormGroup>
          <Label className="certify-information-label" check>
            <Input
              type="checkbox"
              onChange={handleCertifyInformationChange}
              checked={isInformationCertified}
            />{" "}
            This information is accurate.
          </Label>
        </FormGroup>
      )}

      {AdditionalFields}
      {termsAndConditions && (
        <TermsAndConditionsInput
          onTermsConditionsClick={handleToggleTermsAndConditionsModal}
          onChange={handleChangeTermsAndConditions}
          error={errors.agreeTermsAndConditions}
        />
      )}
      <div
        style={{
          cursor: `${!isInformationCertified ? "not-allowed" : "default"}`,
        }}
      >
        <Button
          className={`btn-round ${submitButtonDisabled ? "not-allowed" : ""}`}
          color="neutral"
          onClick={handleSubmit}
          type="submit"
          disabled={submitButtonDisabled}
        >
          {submitButtonText} <i className="fa fa-chevron-right" />
        </Button>
      </div>
      <UncontrolledTooltip placement="top" target="LotSizeTooltip">
        When county data shows this value is zero, it generally indicates that
        your home's appraised value does not take lot size into consideration.
      </UncontrolledTooltip>
    </Form>
  );
};

VerifyAddressForm.propTypes = {
  values: PropTypes.object,
  selectedAddress: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleCertifyInformationChange: PropTypes.func,
  isInformationCertified: PropTypes.bool,
  onChangeCity: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  disableConfirmation: PropTypes.bool,
  disableAll: PropTypes.bool,
  submitButtonText: PropTypes.string,
  termsAndConditions: PropTypes.bool,
  handleChangeTermsAndConditions: PropTypes.func,
  agreeTermsAndConditions: PropTypes.bool,
  handleToggleTermsAndConditionsModal: PropTypes.func,
  AdditionalFields: PropTypes.node,
};

VerifyAddressForm.defaultProps = {
  values: {},
  errors: {},
  handleChange: noop,
  handleCertifyInformationChange: noop,
  disableAll: false,
  disableConfirmation: false,
  submitButtonText: "Just one more step",
  termsAndConditions: false,
  handleChangeTermsAndConditions: noop,
  handleToggleTermsAndConditionsModal: noop,
};

export default VerifyAddressForm;
