import React from "react";
import styled, { css } from "styled-components";
import { NavItem, Nav, Navbar } from "reactstrap";
import { BANNER_HEIGHT } from "../Banner";

export const NavbarLogo = styled.img`
  width: 15%;
`;

export const NavbarItem = styled(NavItem)`
  width: ${({ width }) => width} !important;
`;

export const NavStyled = styled(Nav)`
  .nav-item {
    a {
      transition: 0.2s;

      &:hover {
        opacity: 1 !important;
        text-decoration: underline;
        font-weight: 800;
        font-size: 0.8rem !important;
      }
    }
  }
`;

export const StyledNavbar = styled(Navbar)`
  ${({ bannerOpen }) =>
    bannerOpen &&
    css`
      margin-top: calc(${BANNER_HEIGHT} + 3rem);

      @media (min-width: 540px) {
        margin-top: calc(${BANNER_HEIGHT} + 2rem);
      }

      @media (min-width: 768px) {
      }

      @media (min-width: 992px) {
        margin-top: ${BANNER_HEIGHT};
      }
    `}
`;

export const StyledNavLink = ({ children, ...props }) => (
  <a className="nav-link" {...props}>
    {children}
  </a>
);
