// vendor
import React from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import qs from "qs";
import isEmpty from "lodash/isEmpty";

// app
import MainNavbar from "../../components/Navbar/Navbar";
import FreeAssesmentHeader from "../../components/Header/FreeAssesmentHeader";
import MainFooter from "../../components/Footers/Footer";
import LandingPageSection from "../../components/Sections/LandingPageSection";
import FormCard from "../../components/Cards/FormCard";
import LookupAddressForm from "./LookupAddressForm";
import VerifyAddress from "./VerifyAddress";
import PreOrderFlowConfirmation from "./PreOrderFlowConfirmation";
import {
  getAddressSuggestions,
  getAddressSuggestionsSuccess,
  setAddress,
  setUserEmail,
  setAssessmentFormValues,
  postAssessmentData,
  getPropertyByOrder,
  getPropertyById,
  getPropertyCurrentAssessment,
} from "../../redux/actions/assessment";
import { isPreOrderFlow } from "../../configs";

class FreeAssesmentPageContainer extends React.Component {
  state = {
    assesmentStep: 0,
  };

  componentDidMount() {
    const { getPropertyByOrder, location, setUserEmail, getPropertyById, selectedAddress } = this.props;
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { uuid, email, prop_id } = queryParams;

    if (uuid) {
      getPropertyByOrder(uuid, this.handlePropertyError);
      this.setState({
        assesmentStep: 1,
      });
    }

    if (email && !uuid) {
      setUserEmail(email);
    }

    if (prop_id && !uuid) {
      getPropertyById(prop_id, this.handlePropertyError);
      this.setState({
        assesmentStep: 1,
      });
    }

    if (!isEmpty(selectedAddress)) {
      this.setState({ assesmentStep: 1 });
    }
  }

  handlePropertyError = () => {
    const { history } = this.props;
    history.push("/verify");
    this.setState({ assesmentStep: 0 });
  };

  handleChangeStep = (step) => {
    this.setState({ assesmentStep: step });
  };

  getTitle = () => {
    const { globalLoading, uuid } = this.props;
    let title = "Get Your ";
    if (!(globalLoading || uuid)) {
      title += "Free ";
    }
    title += `Property ${isPreOrderFlow ? "Estimate" : "Assessment"}`;

    return title;
  };

  renderStep = () => {
    const { assesmentStep } = this.state;
    const {
      getAddressSuggestions,
      clearSuggestions,
      selectAddress,
      addressSuggestions,
      selectedAddress,
      setAssessmentFormValues,
      postAssessmentData,
      isAddressSuggestionLoading,
      uuid,
      getPropertyAssessment,
      freeAssesmentFormValues,
    } = this.props;

    const commonProps = {
      onSubmit: this.handleChangeStep,
      selectAddress,
      selectedAddress,
      postAssessmentData,
      uuid,
      getPropertyAssessment,
      assessmentValues: freeAssesmentFormValues,
    };

    switch (assesmentStep) {
      case 0:
        return (
          <LookupAddressForm
            {...commonProps}
            getAddressSuggestions={getAddressSuggestions}
            addressSuggestions={addressSuggestions}
            isLoading={isAddressSuggestionLoading}
            clearSuggestions={clearSuggestions}
          />
        );
      case 1:
        return (
          <VerifyAddress
            {...commonProps}
            setAssessmentFormValues={setAssessmentFormValues}
            clearSuggestions={clearSuggestions}
          />
        );
      case 2:
        return (
          <PreOrderFlowConfirmation
            assessmentValues={freeAssesmentFormValues}
            selectedAddress={selectedAddress}
            onSubmit={this.handleChangeStep}
          />
        );
      default:
        break;
    }
  };

  render() {
    const { globalLoading } = this.props;

    return (
      <>
        <MainNavbar whiteLogo />
        <FreeAssesmentHeader />
        <LandingPageSection titleText={this.getTitle()}>
          {globalLoading ? (
            <Spinner color="info" />
          ) : (
            <Container>
              <Row>
                <Col className="mx-auto" lg="6" md="8" sm="10" xs="12">
                  <FormCard>{this.renderStep()}</FormCard>
                </Col>
              </Row>
            </Container>
          )}
        </LandingPageSection>
        <MainFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addressSuggestions: state.freeAssesment.addressSuggestions,
  selectedAddress: state.freeAssesment.selectedAddress,
  isAddressSuggestionLoading: state.freeAssesment.loading.addressSuggestions,
  customerEmail: state.freeAssesment.customerEmail,
  globalLoading: state.freeAssesment.globalLoading,
  uuid: state.freeAssesment.uuid,
  freeAssesmentFormValues: state.freeAssesment.freeAssesmentFormValues,
});

const mapDispatchToProps = (dispatch) => ({
  getAddressSuggestions: (str) => dispatch(getAddressSuggestions(str)),
  clearSuggestions: () => dispatch(getAddressSuggestionsSuccess([])),
  selectAddress: (address) => dispatch(setAddress(address)),
  setUserEmail: (email) => dispatch(setUserEmail(email)),
  setAssessmentFormValues: (values) => dispatch(setAssessmentFormValues(values)),
  postAssessmentData: (discountObject, isFree = false) => dispatch(postAssessmentData(discountObject, isFree)),
  getPropertyByOrder: (uuid, handleError) => dispatch(getPropertyByOrder(uuid, handleError)),
  getPropertyById: (propId, handleError) => dispatch(getPropertyById(propId, handleError)),
  getPropertyAssessment: () => dispatch(getPropertyCurrentAssessment()),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRedux, withRouter)(FreeAssesmentPageContainer);
