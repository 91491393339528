// vendor
import React from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";


// app
import LandingPageSection from "../../components/Sections/LandingPageSection";
import InstructionCard from "../../components/Cards/InstructionCard";
import {
  SALMON_BACKGROUND_COLOR,
  BLACK_FONT_COLOR,
} from "../../constants/colors";
import { landingPage } from "../../constants/descriptions";
import { DEMO_REPORT_URL } from "../../constants";
import { SecondSectionCardsContainer } from "./styled";

const SectionContainer = styled.div`
  margin-bottom: 2rem;
`;

const SecondSection = ({ goFreeAssesment }) => {
  const { secondSection } = landingPage;

  const onClickFunction = {
    seeDemo: () => {
      window.open(DEMO_REPORT_URL);
    },
    verifyAppraisal: () => {
      goFreeAssesment();
    },
    goAbout: () => {
      window.open("/about");
    }
  };

  return (
    <SectionContainer>
      <LandingPageSection
        titleText={secondSection.title}
        sectionStyles={{ padding: "1.5rem 0" }}
        titleStyles={{ color: BLACK_FONT_COLOR }}
        bgColor={SALMON_BACKGROUND_COLOR}
      />
      <SecondSectionCardsContainer>
        <Row>
          {secondSection.cards.map((card) => (
            <Col lg={3} md={6} sm={12} key={`${card.title}`}>
              <InstructionCard
                title={card.title}
                bgNumber={card.bgNumber}
                buttonText={card.buttonText}
                onClick={onClickFunction[card.functionCallback]}
              >
                {card.paragraphs.map((p) => (
                  <p
                    key={`${card.title}-${p[0]}`}
                    style={{ textAlign: "justify" }}
                  >
                    {p}
                  </p>
                ))}
              </InstructionCard>
            </Col>
          ))}
        </Row>
      </SecondSectionCardsContainer>
    </SectionContainer>
  );
};

SecondSection.propTypes = {
  goFreeAssesment: PropTypes.func.isRequired,
};

export default SecondSection;
