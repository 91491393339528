import Container from "reactstrap/lib/Container";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import { BLUE_BACKGROUND_COLOR, LIGHT_GREEN_COLOR } from "../../constants/colors";

export const PanelContainer = styled(Container)`
  padding-top: 3rem;
  text-align: center !important;
  @media (min-width: 992px) {
    max-width: 50%;
  }
`;

export const TestimonialSlideContainer = styled(Carousel)`
  .slide {
    background: #fff;
    color: black;
    height: 100%;
    width: 30% !important;

    img {
      width: 7.5rem !important;
      border-radius: 50%;
      box-shadow: 0 3px 5px 0 rgb(0 0 0 / 40%);
    }
  }

  .control-arrow {
    background: ${LIGHT_GREEN_COLOR} !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
  }

  .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;

    .dot {
      box-shadow: none !important;
      background: ${BLUE_BACKGROUND_COLOR} !important;
      outline: 0;
    }
  }

  @media only screen and (max-width: 934px) {
    outline: 0;
    width: 93% !important;
    margin: auto !important;

    .control-arrow {
      display: none !important;
    }

    .slide {
      img {
        width: 24% !important;
        border-radius: 50%;
      }
    }
  }
`;

export const TestimonialSlideContent = styled.div`
  background: #fafafa;
  // margin-top: -6%; WHEN ADDING PICTURE TO TESTIMONIALS, DELETE COMMENT
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  border-top: 4px solid ${BLUE_BACKGROUND_COLOR};
  height: 23rem;

  h3 {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
  }

  h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
  }

  hr {
    width: 15%;
    border-top: 3px solid ${BLUE_BACKGROUND_COLOR};
  }

  p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #222;
    font-size: 15px;
    font-family: sans-serif;

    &:before {
      content: "“";
      color: #aaa;
      font-size: 26px;
      font-family: monospace;
      font-weight: 100;
    }

    &:after {
      content: "”";
      color: #aaa;
      font-size: 26px;
      font-family: monospace;
      font-weight: 100;
      line-height: 0;
    }
  }

  @media only screen and (max-width: 992px) {
    width: 90%;
    padding-right: 5%;
  }

  @media only screen and (max-width: 576px) {
    width: 95%;
    height: 27rem;
  }
`;
