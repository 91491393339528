import styled from "styled-components";
import { CardTitle, CardBody } from "reactstrap";

const BackgroundNumberTopOffset = "5rem";
const BackgroundNumberLeftOffset = "1rem";

export const BackgroundNumber = styled.div`
  opacity: 0.5;
  position: absolute;
  font-size: 11rem;
  color: ${({ color }) => color || "#bfc8d7"};
  top: -${BackgroundNumberTopOffset};
  left: -${BackgroundNumberLeftOffset};
  z-index: -5;
  font-weight: 600;
  font-family: sans-serif;
`;

export const InstructionTitle = styled(CardTitle)`
  font-weight: 600;
  min-height: calc(12rem - ${BackgroundNumberTopOffset});
`;

export const InstructionBody = styled(CardBody)`
  text-align: left;
  margin: 0 auto;
  width: 80%;

  p {
    margin-bottom: 1rem;
  }
`;
