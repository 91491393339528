// vendor
import React from "react";
import { Row, Col } from "reactstrap";

// app
import MainNavbar from "../../components/Navbar/Navbar";
import MainFooter from "../../components/Footers/Footer";
import { pricingText } from "../../constants/descriptions";
import { ContainerSectionOne } from "./styled";
import FreeAssesmentHeader from "../../components/Header/FreeAssesmentHeader";
import { StepContainer } from "./styled";
import { ContainerSectionTwo } from "./styled";
import { SectionTwoWrapper } from "./styled";
import { SectionTwoParagraph } from "./styled";
import { HeaderButton } from "../../components/Buttons";
import { useHistory } from "react-router";

const PricingPage = () => {
  const history = useHistory();
  const { firstSection, secondSection } = pricingText;

  const onClickGetReport = () => {
    history.push("/verify");
  };

  return (
    <>
      <MainNavbar />
      <FreeAssesmentHeader minHeight="20vh" />
      <ContainerSectionOne>
        <Row className="title_container">
          <Col sm={12}>
            <h3 className="title">{firstSection.title}</h3>
            <div>{firstSection.subtitle}</div>
          </Col>
        </Row>
        <Row className="prices_steps_container">
          {firstSection.paragraphs.map((p) => (
            <Col lg={6} sm={12} className="pr-0 pl-0 pt-0 pb-0" key={p.title}>
              <StepContainer bgcolor={p.background}>
                <div className="step_title">{p.title}</div>
                {p.icon()}
                <div className="text">{p.text}</div>
              </StepContainer>
            </Col>
          ))}
        </Row>
        <Row className="pricing_cta">
          <HeaderButton onClick={onClickGetReport}>
            Get Your Report Now <i className="fa fa-chevron-right" />
          </HeaderButton>
        </Row>
      </ContainerSectionOne>
      <SectionTwoWrapper>
        <ContainerSectionTwo>
          <Row>
            <Col sm={12}>
              <div className="title_second_section">{secondSection.title}</div>
            </Col>
          </Row>
          {secondSection.paragraphs.map((p, i) => (
            <SectionTwoParagraph key={i}>
              <Col sm={12}>{p}</Col>
            </SectionTwoParagraph>
          ))}
          <Row className="pricing_cta">
            <HeaderButton onClick={onClickGetReport}>
              Get Your Report Now <i className="fa fa-chevron-right" />
            </HeaderButton>
          </Row>
        </ContainerSectionTwo>
      </SectionTwoWrapper>
      <MainFooter />
    </>
  );
};

export default PricingPage;
