import React from "react";
import { Container, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';

import { JumboContainer } from "../Loaders/PageLoader";

const OrderNotFound = ({ title }) => (
  <Container style={{ padding: 0 }}>
    <JumboContainer>
      <Row>
        <Col>
          <h4 className="title">
            {title}{" "}
            <a href="mailto:help@protestingpropertytaxes.com">
              help@protestingpropertytaxes.com
            </a>
          </h4>
        </Col>
      </Row>
    </JumboContainer>
  </Container>
);

OrderNotFound.propTypes = {
  title: PropTypes.string,
}

OrderNotFound.defaultProps = {
  title: "Sorry, but we couldn't find your pending order. Maybe you have already paid? If you need help, please email"
}

export default OrderNotFound;
