// vendor
import React, { useRef } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import classnames from "classnames";
import { useSelector } from "react-redux";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
// reactstrap components
import { Collapse, NavbarBrand, NavItem, NavLink, Container } from "reactstrap";

// app
import { NavbarLogo, NavStyled, StyledNavbar, StyledNavLink } from "./styled";
import LogoGray from "../../assets/img/logo_no_text.png";
import { BLOG_URL } from "../../constants";
import { landingPage } from "../../constants/descriptions";
import Banner, { BannerLink } from "../Banner";

function MainNavbar({ fixedColor }) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const navbarNode = useRef();
  const { brokerReferred } = useSelector((state) => state.session);
  const bannerOpen = !!brokerReferred;

  const toggleNavbarCollapse = () => {
    setNavbarCollapse((prev) => !prev);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const handleClick = (e) => {
      if (navbarNode.current.contains(e.target) || !navbarCollapse) {
        return;
      }

      toggleNavbarCollapse();
    };
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [navbarCollapse]);

  React.useEffect(() => {
    if (document.documentElement.classList.contains("nav-open") && !navbarCollapse) {
      document.documentElement.classList.toggle("nav-open");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 99 || document.body.scrollTop > 99) {
        setNavbarColor("");
      } else if (document.documentElement.scrollTop < 100 || document.body.scrollTop < 100) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {brokerReferred && brokerReferred.name && (
        <Banner>
          <p>
            Get your free assessment and discounted report thanks to <b>{startCase(toLower(brokerReferred.name))}'s</b>{" "}
            participation in our{" "}
            <BannerLink target="_blank" to="/terms-and-conditions#referral-program">
              {" "}
              Broker Referral Program!
            </BannerLink>
          </p>
        </Banner>
      )}
      <div ref={navbarNode}>
        <StyledNavbar
          className={classnames("fixed-top", fixedColor ? "" : navbarColor)}
          color-on-scroll="300"
          expand="lg"
          bannerOpen={bannerOpen}
        >
          <Container>
            <div className="navbar-translate">
              <NavbarBrand data-placement="bottom" to="/verify" title="Protesting Property Taxes" tag={RRNavLink}>
                {!navbarColor && <NavbarLogo src={LogoGray} alt="logo" />}
                {!navbarColor && "ProtestingPropertyTaxes"}
              </NavbarBrand>
              <button
                aria-expanded={navbarCollapse}
                className={classnames("navbar-toggler navbar-toggler", {
                  toggled: navbarCollapse,
                })}
                onClick={toggleNavbarCollapse}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
              <NavStyled navbar>
                <NavItem>
                  <NavLink to="/" tag={RRNavLink} activeClassName="active">
                    <i className="fa fa-home" />
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/pricing" tag={RRNavLink} activeClassName="active">
                    Pricing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/faq" tag={RRNavLink} activeClassName="active">
                    FAQ
                  </NavLink>
                </NavItem>
                <NavItem>
                  <StyledNavLink href={BLOG_URL}>Blog</StyledNavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/verify" tag={RRNavLink} activeClassName="active">
                    {landingPage.navbar.getASsessmentButtonText()}
                  </NavLink>
                </NavItem>
              </NavStyled>
            </Collapse>
          </Container>
        </StyledNavbar>
      </div>
    </>
  );
}

export default MainNavbar;
