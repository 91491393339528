import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

// app
import VerifyAddressForm from "../../components/VerifyAddressForm";
import { GoBackButton } from "../../components/Buttons";
import TermsAndConditionsModal from "../../components/Modals/TermsAndConditionsModal";
import { FormError } from "../../components/Cards/FormCard";
import MailCheckInput from "../../components/MailCheck";
import { titleCase, validateEmail } from "../../utils";
import { postAssessmentData, setUserEmail } from "../../redux/actions/assessment";
import { withFormik } from "formik";
import { useHistory } from "react-router";

const CURRENT_STEP = 2;

const PreOrderConfirmationFlow = ({ selectedAddress, values, onSubmit }) => {
  const [isInformationCertified, certifyInformation] = useState(true);
  const [agreeTermsAndConditions, setAgreeTermsAndConditions] = useState(false);
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
  const [email, setEmail] = useState({
    value: "",
    error: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const goBack = () => {
    onSubmit(CURRENT_STEP - 1);
  };

  const handleCertifyInformationChange = () => {
    certifyInformation((prev) => !prev);
  };

  const handleChangeTermsAndConditions = () => {
    setAgreeTermsAndConditions((prev) => !prev);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const errors = {};
    if (email.error || !email.value) {
      errors.email = "Must be valid email";
    }

    if (!agreeTermsAndConditions) {
      errors.agreeTermsAndConditions = "You must agree with our Terms and Conditions";
    }

    if (isEmpty(errors)) {
      // sets email to store and calls begin-computation
      dispatch(setUserEmail(email.value));
      dispatch(postAssessmentData(null, null, true));
      history.push("/pre-order/submit");
    } else {
      setFormErrors(errors);
    }
  };

  const handleToggleTermsAndConditionsModal = () => {
    setTermsAndConditionsModal((prev) => !prev);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const isValid = validateEmail(email);

    if (isValid && formErrors.email) {
      setFormErrors({ ...formErrors, email: "" });
    }

    setEmail({ value: email, error: !isValid });
  };

  const EmailField = (
    <MailCheckInput inputText={email.value} handleEmailChange={handleEmailChange} useInputAddon={false}>
      {formErrors.email && <FormError>Must enter a valid Email Address.</FormError>}
      <small>We need to know where to send your report. We'll never share your email with anyone.</small>
    </MailCheckInput>
  );

  return (
    <>
      <GoBackButton title="Change Address" onClick={goBack} />
      <VerifyAddressForm
        goBack={goBack}
        values={values}
        disableAll
        selectedAddress={selectedAddress}
        handleCertifyInformationChange={handleCertifyInformationChange}
        isInformationCertified={isInformationCertified}
        agreeTermsAndConditions={agreeTermsAndConditions}
        handleChangeTermsAndConditions={handleChangeTermsAndConditions}
        termsAndConditions
        handleSubmit={handleFormSubmit}
        handleToggleTermsAndConditionsModal={handleToggleTermsAndConditionsModal}
        submitButtonText="Submit"
        submitButtonDisabled={!isInformationCertified}
        AdditionalFields={EmailField}
        errors={formErrors}
      />
      <TermsAndConditionsModal isOpen={termsAndConditionsModal} toggle={handleToggleTermsAndConditionsModal} />
    </>
  );
};

const CreatePreOrderConfirmationWithFormik = withFormik({
  mapPropsToValues: ({
    selectedAddress: { bed_count, bath_count, building_sq_ft, lot_sq_ft, year_built, city },
    assessmentValues,
  }) => ({
    bedCount: assessmentValues.bed_count || bed_count || "",
    bathCount: assessmentValues.bath_count || bath_count || "",
    buildingSqFt: assessmentValues.building_sq_ft || building_sq_ft || "",
    lotSqFt:
      assessmentValues.lot_sq_ft || assessmentValues.lot_sq_ft === 0
        ? assessmentValues.lot_sq_ft
        : lot_sq_ft || lot_sq_ft === 0
        ? lot_sq_ft
        : "",
    yearBuilt: assessmentValues.year_built || year_built || "",
    city: assessmentValues.city || (city ? titleCase(city) : ""),
  }),
});

PreOrderConfirmationFlow.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  selectedAddress: PropTypes.object,
};

export default CreatePreOrderConfirmationWithFormik(PreOrderConfirmationFlow);
