import React from "react";
import PropTypes from "prop-types";

import { Card, CardFooter } from "reactstrap";
import { HeaderButton } from "../Buttons";
import { BackgroundNumber, InstructionTitle, InstructionBody } from "./styled";

const InstructionCard = ({
  children,
  buttonText,
  bgNumber,
  title,
  onClick,
}) => (
  <Card className="card-plain">
    {bgNumber && (
      <BackgroundNumber color={bgNumber.color}>
        {bgNumber.text}
      </BackgroundNumber>
    )}
    <InstructionBody>
      <InstructionTitle tag="h3">{title}</InstructionTitle>
      {children}
    </InstructionBody>
    {buttonText && (
      <CardFooter className="text-center">
        <HeaderButton onClick={onClick}>{buttonText}</HeaderButton>
      </CardFooter>
    )}
  </Card>
);

InstructionCard.propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
  bgNumber: PropTypes.object,
  title: PropTypes.string,
};

export default InstructionCard;
