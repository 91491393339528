import { GET_BROKER_BY_REFERRAL_SUCCESS, GET_BROKER_BY_REFERRAL_FAIL, GET_BROKER_BY_REFERRAL } from "../actionTypes";

const initialState = {
  brokerReferred: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BROKER_BY_REFERRAL: {
      return { ...state, brokerReferred: null };
    }
    case GET_BROKER_BY_REFERRAL_FAIL: {
      return { ...state, brokerReferred: null };
    }
    case GET_BROKER_BY_REFERRAL_SUCCESS:
      return { ...state, brokerReferred: payload };

    default:
      return state;
  }
};
