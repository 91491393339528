// vendor
import React from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import isEmpty from "lodash/isEmpty";

// app
import MainNavbar from "../../components/Navbar/Navbar";
import FreeAssesmentHeader from "../../components/Header/FreeAssesmentHeader";
import MainFooter from "../../components/Footers/Footer";
import LandingPageSection from "../../components/Sections/LandingPageSection";
import FormCard from "../../components/Cards/FormCard";
import GetAssessmentForm from "./GetAssessmentForm";
import ThankYouInfo from "./ThankYouInfo";
import { setAddress, setUserEmail, postAssessmentData } from "../../redux/actions/assessment";

class SubmitAssessmentContainer extends React.Component {
  state = {
    assesmentStep: 0,
  };

  componentDidMount() {
    const { selectedAddress } = this.props;
    if (isEmpty(selectedAddress)) {
      this.props.history.push("/verify");
    }
  }

  handleChangeStep = (step) => {
    this.setState({ assesmentStep: step });
  };

  renderStep = () => {
    const { assesmentStep } = this.state;
    const { selectedAddress, setUserEmail, postAssessmentData, customerEmail, uuid } = this.props;

    const commonProps = {
      onSubmit: this.handleChangeStep,
      selectedAddress,
      postAssessmentData,
      uuid,
      setUserEmail: setUserEmail,
      customerEmail: customerEmail,
    };

    switch (assesmentStep) {
      case 0:
        return <GetAssessmentForm {...commonProps} />;
      case 1:
        return <ThankYouInfo />;
      default:
        break;
    }
  };

  render() {
    const { globalLoading } = this.props;

    return (
      <>
        <MainNavbar whiteLogo />
        <FreeAssesmentHeader />
        <LandingPageSection titleText={`Submit Your Property Assessment`}>
          {globalLoading ? (
            <Spinner color="info" />
          ) : (
            <Container>
              <Row>
                <Col className="mx-auto" lg="6" md="8" sm="10" xs="12">
                  <FormCard>{this.renderStep()}</FormCard>
                </Col>
              </Row>
            </Container>
          )}
        </LandingPageSection>
        <MainFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addressSuggestions: state.freeAssesment.addressSuggestions,
  selectedAddress: state.freeAssesment.selectedAddress,
  isAddressSuggestionLoading: state.freeAssesment.loading.addressSuggestions,
  customerEmail: state.freeAssesment.customerEmail,
  globalLoading: state.freeAssesment.globalLoading,
  uuid: state.freeAssesment.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  selectAddress: (address) => dispatch(setAddress(address)),
  setUserEmail: (email) => dispatch(setUserEmail(email)),
  postAssessmentData: (discountObject, isFree = false) => dispatch(postAssessmentData(discountObject, isFree)),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRedux, withRouter)(SubmitAssessmentContainer);
