import React from "react";
import { testimonials } from "../../constants/descriptions";
import {
  PanelContainer,
  TestimonialSlideContainer,
  TestimonialSlideContent,
} from "./styled";

const TestimonialsPanel = () => {
  return (
    <PanelContainer>
      <TestimonialSlideContainer
        showArrows
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        autoPlay
        interval={5000}
        emulateTouch
      >
        {testimonials.map((testimonial) => (
          <div key={testimonial.name}>
            {/*
              TODO: DELETE COMMENT IN CASE WE WANT TO ADD PICTURES TO TESTIMONIALS
              <img src={require("./profile-placeholder.png")} alt="profile_img" /> */}
            <TestimonialSlideContent>
              <h3>{testimonial.name}</h3>
              <h4>{testimonial.county}</h4>
              <hr />
              <p>{testimonial.content}</p>
            </TestimonialSlideContent>
          </div>
        ))}
      </TestimonialSlideContainer>
    </PanelContainer>
  );
};

export default TestimonialsPanel;
