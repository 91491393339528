import styled from "styled-components";

export const CreditsFooter = styled.div`
  margin-left: auto;
`;

export const ColoredFooter = styled.footer``;

export const ContactSection = styled.div`
  a {
    display: inline-block !important;
  }
`;
