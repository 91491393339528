// vendor
import React from "react";

import { FreeAssesmentHeaderContainer } from "./styled";

function FreeAssesmentHeader({ minHeight }) {
  return (
    <FreeAssesmentHeaderContainer
      className="page-header"
      data-parallax={true}
      image={require("../../assets/img/landing-page-image-2.jpg")}
      minHeight={minHeight}
    >
      <div className="filter" />
    </FreeAssesmentHeaderContainer>
  );
}

export default FreeAssesmentHeader;
