import { API_URL, ONE_TIME_PRICE } from "../constants";

export const titleCase = (string) => {
  if (!string) {
    return "";
  }
  var sentence = string.toLowerCase().split(" ");

  for (var i = 0; i < sentence.length; i++) {
    if (sentence[i][0]) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
  }
  return sentence.join(" ").replace("Unit", "");
};

export const formatAddress = (address) => {
  const whole_address = `${address.canonical_address}${
    address.city ? `, ${address.city} - ` : ", "
  }${address.zip_code}`;
  return titleCase(whole_address);
};

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const toCurrency = (value, currency = "USD", negative = false) => {
  if (negative) {
    value *= -1;
  }

  return `$${value.toLocaleString("en-US")}`;
};

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const roundToNearestHundreth = (value) => Math.round(value / 100) * 100;

export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const loadSetupIntent = () => {
  return fetch(`${API_URL}/orders/create-setup-intent/`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then(function (response) {
      return response.json();
    })
    .catch((err) => {
      console.log("Error loading Setup Intent: ", err);
      return false;
    });
};

export const getDiscountedPrice = (
  discountPercentage,
  price = ONE_TIME_PRICE
) => Math.round((100 - discountPercentage) * price / 100 ) 
