import { Col } from "reactstrap";
import styled, { css } from "styled-components";

export const ReportContainer = styled(Col)`
  background-color: ${({ bgcolor }) => bgcolor};

  cursor: pointer;
  padding: 2rem 1rem;
  height: 100%;

  border-radius: 4px;
  border: 2px solid transparent;

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
      border: 2px solid black;
    `}

  &:hover {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  }
  .step_title {
    font-size: 20px;
    font-weight: 500;
  }

  .price_title {
    font-size: 36px;
    font-weight: 800;
    margin: 1rem 0;

    .discounted {
      text-decoration: line-through;
      font-size: 26px;
      font-weight: 400;
      font-style: italic;
    }
  }

  img {
    width: 80px;
    margin: 1rem 0;
  }

  li {
    text-align: left;
    margin-bottom: 0.3rem;
  }
`;
