import React, { useState } from "react";
import styled from "styled-components";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  InputGroupText,
  Spinner,
  ModalFooter,
} from "reactstrap";
import classnames from "classnames";
import { getDiscountCode, postDiscountCodeToOrder } from "../../redux/actions/assessment";
import { FormButton } from "../Buttons";
import { BLUE_BACKGROUND_COLOR } from "../../constants/colors";

export const CodeHere = styled.i`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${BLUE_BACKGROUND_COLOR};
  }
`;

function AssessmentCodeModal({ isOpen, toggle, submitCode, orderId }) {
  const [code, setCode] = useState({
    value: "",
    error: null,
    loading: false,
    discountPercentage: null,
  });

  const handleChangeCode = (e) => {
    const value = e.target.value;

    setCode({ value, error: null });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCode({ ...code, loading: true });
    if (orderId) {
      postDiscountCodeToOrder(code.value, orderId).then(onCodeSuccessful).catch(onCodeError);
    } else {
      getDiscountCode(code.value).then(onCodeSuccessful).catch(onCodeError);
    }
  };

  const onCodeSuccessful = (res) => {
    const { data } = res;
    const discountObject = {
      value: code.value,
      error: null,
      loading: false,
      discountPercentage: data.discount_percentage,
    };

    submitCode(discountObject);
    setCode(discountObject);
    toggle();
  };

  const onCodeError = () => {
    setCode({
      value: code.value,
      error: true,
      loading: false,
      discountPercentage: null,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} onSubmit={handleSubmit}>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <h5>Enter your code below for a partial or full discount on your Appraisal Contestation report</h5>
        <div className="title">
          <Form>
            <FormGroup
              className={classnames({
                "has-danger": code.error,
                "has-success": code.discountPercentage,
              })}
            >
              <InputGroup className="form-group-no-border">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    {code.loading ? (
                      <Spinner color="info" style={{ height: "17px", width: "17px" }} />
                    ) : (
                      <i className="fa fa-barcode" />
                    )}
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Report Code"
                  value={code.value}
                  onChange={handleChangeCode}
                  className={classnames({
                    "form-control-danger": code.error,
                    "form-control-success": code.discountPercentage,
                  })}
                />
              </InputGroup>
              {code.error && (
                <div>
                  <i style={{ color: "red" }}>Invalid Code</i>
                </div>
              )}
              {code.discountPercentage && code.discountPercentage < 100 && (
                <div>
                  <i>
                    <b>
                      Discount code {code.value} applied ({code.discountPercentage}% savings!)
                    </b>
                  </i>
                </div>
              )}
              {code.discountPercentage === 100 && (
                <div>
                  <i>
                    <b>Free Report Code {code.value} applied! </b>
                  </i>
                </div>
              )}
            </FormGroup>
          </Form>
        </div>
      </ModalBody>
      <ModalFooter>
        <FormButton className="btn-round mb-4" size="lg" onClick={handleSubmit}>
          Submit Code <i className="fa fa-chevron-right" />
        </FormButton>
      </ModalFooter>
    </Modal>
  );
}

export default AssessmentCodeModal;
