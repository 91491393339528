import React from "react";
import { Input, FormGroup, Label } from "reactstrap";

import { FormError } from "../../Cards/FormCard";
import { ModalTogglerStyled } from "../../Modals/TermsAndConditionsModal";

const TermsAndConditionsInput = ({
  onChange,
  error,
  onTermsConditionsClick,
}) => {
  const handleOnClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onTermsConditionsClick();
  }

  const handleOnChange = (e) => {
    onChange(e);
  }

  return (
    <FormGroup>
      <Label check>
        <Input
          type="checkbox"
          onChange={handleOnChange}
        />{" "}
        <small>
          I acknowledge that I have read and understand the{" "}
          <ModalTogglerStyled onClick={handleOnClick}>
            terms and conditions
          </ModalTogglerStyled>{" "}
          associated with this service.{" "}
        </small>
      </Label>
      {error && (
        <FormError>You must agree with our Terms and Conditions</FormError>
      )}
    </FormGroup>
  )
};

export default TermsAndConditionsInput;
