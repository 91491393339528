// vendor
import React from "react";
import { useHistory } from "react-router-dom";

// app
import Navbar from "../../components/Navbar/Navbar";
import LandingPageHeader from "../../components/Header/LandingPageHeader";
import LandingPageFooter from "../../components/Footers/Footer";
import FirstSection from "./FirstSection";
import LandingPageSection from "../../components/Sections/LandingPageSection";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import { landingPage } from "../../constants/descriptions";
import BottomSection from "./BottomSection";
import { LandingPageContainer } from "./styled";
import TestimonialsSection from "./TestimonialsSection";

const LandingPage = () => {
  const history = useHistory();

  const goFreeAssesment = () => {
    history.push("/verify");
  };

  const renderMainSection = () => (
    <div className="main">
      <FirstSection />
      <TestimonialsSection />
      <SecondSection goFreeAssesment={goFreeAssesment} />
      <LandingPageSection
        buttonText={landingPage.appraisalFairSection.button}
        titleText={landingPage.appraisalFairSection.title}
        onButtonClick={goFreeAssesment}
      />
      <ThirdSection />
      <BottomSection />
    </div>
  );

  return (
    <>
      <Navbar />
      <LandingPageContainer>
        <LandingPageHeader onClickFreeAssesment={goFreeAssesment} />
        {renderMainSection()}
        <LandingPageFooter />
      </LandingPageContainer>
    </>
  );
};

export default LandingPage;
