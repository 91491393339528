import React from "react";
import styled from "styled-components";

import { Card, Col } from "reactstrap";
import { BLACK_FONT_COLOR, SALMON_BACKGROUND_COLOR } from "../../constants/colors";

const FAQCardComponent = styled(Card)`
  border-radius: 0px;
  color: ${BLACK_FONT_COLOR};
  margin: 0;
  padding: 1rem 2rem 0 2rem;
  text-align: left;

  .card-header {
    background-color: #fff;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .card-body {
    font-weight: 400;
  }

  a {
    color: ${SALMON_BACKGROUND_COLOR} !important;
    font-weight: 500;
  }

  &:hover {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15) !important;
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    -moz-transform: none !important;
  }
`;

export const FAQContainer = styled(Col)`
  padding: 1rem;

  .card:last-child {
    padding-bottom: 1rem;
  }
`;

const FAQCard = ({ children, id }) => (
  <FAQCardComponent id={id}>{children}</FAQCardComponent>
);

export default FAQCard;
