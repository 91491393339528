import styled, { css } from "styled-components";
import { Col, Container } from "reactstrap";

const landingPageImageStyles = css`
  width: 103%;
  display: inline-block;
  height: 470px;
`;

const landingPageImageTitleStyles = css`
  margin-top: 0;
  font-weight: 600;
  z-index: -5;

  @media (max-width: 991.98px) {
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

const offsetStyles = css`
  z-index: 3;
  h2 {
    margin-right: -60%;
  }
  p {
    margin-right: -32%;
    margin-left: 35%;
  }

  @media (max-width: 991.98px) {
    h2,
    p {
      margin: 0;
      margin-bottom: 1.5rem;
    }
  }
`;

export const FirstSectionImage = styled(Col)`
  img {
    ${landingPageImageStyles}
    -moz-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  @media (max-width: 991.98px) {
    display: none;
  }
`;

export const ThirdSectionImage = styled(Col)`
  margin-top: 5rem;
  img {
    ${landingPageImageStyles}
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  @media (max-width: 991.98px) {
    display: none;
  }
`;

export const DescriptionContainer = styled(Col)`
  ${({ offsetright }) => offsetright && offsetStyles}
`;

export const FirstSectionTitle = styled.h2`
  margin-left: -45%;
  margin-right: 23%;

  text-align: right;
  ${landingPageImageTitleStyles}
`;

export const ThirdSectionTitle = styled.h2`
  ${landingPageImageTitleStyles};
  font-size: 3.2rem;
`;

export const DescriptionParagraphs = styled.div`
  text-align: left;
  width: 80%;
  p {
    font-size: 1rem;

    b {
      font-size: 1.1rem;
      font-weight: 600;
    }
    margin-bottom: 1rem;
  }

  @media (max-width: 991.98px) {
    width: 100%;
    text-align: justify;
  }
`;

export const SecondSectionCardsContainer = styled(Container)`
  padding-top: 4.375rem;
  text-align: center !important;

  @media (min-width: 1200px) {
    max-width: 80%;
  }

`;

export const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
