// vendor
import React from "react";
import PropTypes from "prop-types";

// app
import {
  HeaderSubtitleContainer,
  HeaderContainer,
  HeaderTitle,
  MottoHeaderContainer,
} from "./styled";
import { HeaderButton } from "../Buttons";
import { landingPage } from "../../constants/descriptions";

function LandingPageHeader({ onClickFreeAssesment }) {
  return (
    <HeaderContainer
      className="page-header"
      data-parallax={true}
      image={require("../../assets/img/background-header.jpg")}
    >
      <div className="filter" />
      <MottoHeaderContainer>
        <div className="motto">
          <HeaderTitle>{landingPage.header.getTitle()}</HeaderTitle>
          <HeaderSubtitleContainer>
            <h3>{landingPage.header.getSubtitle1()}</h3>
            <h3>{landingPage.header.getSubtitle2()}</h3>
          </HeaderSubtitleContainer>
          <br /> <br />
          <HeaderButton onClick={onClickFreeAssesment} size="lg">
            {landingPage.header.getButtonText()} <i className="fa fa-chevron-right" />
          </HeaderButton>
        </div>
      </MottoHeaderContainer>
    </HeaderContainer>
  );
}

LandingPageHeader.propTypes = {
  onClickFreeAssesment: PropTypes.func.isRequired,
};

export default LandingPageHeader;
