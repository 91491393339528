import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

import { HeaderButton } from "../../components/Buttons";
import { BLUE_BACKGROUND_COLOR } from "../../constants/colors";

const Title = styled.h2`
  font-weight: 600;
  max-width: 80%;
  color: ${({ color }) => color};
  width: ${({ width }) => width};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const SectionContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`;

const LandingPageSection = ({
  buttonText,
  titleText,
  bgColor,
  children,
  sectionStyles,
  titleStyles,
  onButtonClick,
}) => (
  <SectionContainer
    className="section text-center"
    bgColor={bgColor}
    {...sectionStyles}
  >
    <Container>
      {titleText && (
        <Title className="title ml-auto mr-auto" {...titleStyles}>
          {titleText}
        </Title>
      )}
      {buttonText && (
        <HeaderButton className="ml-auto mr-auto" onClick={onButtonClick}>
          {buttonText}
        </HeaderButton>
      )}
      {children}
    </Container>
  </SectionContainer>
);

LandingPageSection.propTypes = {
  buttonText: PropTypes.string,
  titleText: PropTypes.string,
  bgColor: PropTypes.string,
  children: PropTypes.node,
  sectionStyles: PropTypes.object,
  titleStyles: PropTypes.object,
  onButtonClick: PropTypes.func,
};

LandingPageSection.defaultProps = {
  bgColor: BLUE_BACKGROUND_COLOR,
  titleStyles: {
    color: "#fff",
  },
};

export default LandingPageSection;
