// vendor
import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router";

// app
import MainNavbar from "../../components/Navbar/Navbar";
import MainFooter from "../../components/Footers/Footer";
import FreeAssesmentHeader from "../../components/Header/FreeAssesmentHeader";
import { termsAndConditions } from "../../constants/termsAndConditions";

const TermsAndConditionsPage = () => {
  const location = useLocation();
  const { hash } = location;

  useEffect(() => {
    if (hash) {
      const section = hash.split("#")[1];
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [hash]);

  return (
    <>
      <MainNavbar />
      <FreeAssesmentHeader minHeight="20vh" />
      <Container>
        <h1 className="title">Terms and Conditions</h1>
        <Row>
          <Col style={{ textAlign: "justify" }}>{termsAndConditions}</Col>
        </Row>
      </Container>
      <MainFooter />
    </>
  );
};

export default TermsAndConditionsPage;
