import styled from "styled-components";
import { Row } from "reactstrap";
import { SALMON_BACKGROUND_COLOR } from "../../constants/colors";

export const AboutContainer = styled(Row)`
  text-align: justify;
  margin: 4rem 0;
  .image-container {
    margin: 2.5rem 0;
    img {
      width: 100%;
    }
  }
  p {
    margin-bottom: 2rem;
  }

  a {
    color: ${SALMON_BACKGROUND_COLOR} !important;
    font-weight: 500;
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    margin: 4rem 2rem;
  }

  @media (min-width: 992px) {
    margin: 4rem 5rem;
  }

  @media (min-width: 1200px) {
    margin: 4rem 10rem;
    .image-container {
      margin-top: 3.5rem;
      text-align: center;
    }
  }
`;
