// vendor
import React from "react";
import { Container, Row, Col } from "reactstrap";

// app
import MainNavbar from "../../components/Navbar/Navbar";
import MainFooter from "../../components/Footers/Footer";
import AboutHeader from "../../components/Header/AboutHeader";
import { AboutContainer } from "./styled";
import { AboutUsText } from "../../constants/descriptions";
import BobAndMelinda from "../../assets/img/Bob-Melinda-McKenna.png";

const About = () => {
  const { paragraphs } = AboutUsText;
  return (
    <>
      <MainNavbar whiteLogo />
      <AboutHeader image={require("../../assets/img/background.jpg")} />
      <Container>
        <AboutContainer>
          <Row>
            <Col md={6} sm={12}>
              {paragraphs[0]()}
              {paragraphs[1]()}
            </Col>
            <Col md={6} sm={12}>
              <div className="image-container">
                <img src={BobAndMelinda} alt="Bob and Melinda McKenna" />
                <small>
                  Bob and Melinda McKenna, founders of Austin Real Estate
                  Partners
                </small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              {paragraphs[2]()}
            </Col>
            <Col md={6} sm={12}>
              {paragraphs[3]()}
            </Col>
          </Row>
        </AboutContainer>
      </Container>
      <MainFooter />
    </>
  );
};

export default About;
