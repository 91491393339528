import React, { useState } from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { compose } from "redux";
import { withRouter } from "react-router";

// app
import { titleCase } from "../../utils";
import VerifyAddressForm from "../../components/VerifyAddressForm";
import { GoBackButton } from '../../components/Buttons';
import { isPreOrderFlow } from "../../configs";

const CURRENT_STEP = 1;

const VerifyAddress = ({
  selectedAddress,
  handleChange,
  values,
  errors,
  handleSubmit,
  onSubmit,
  selectAddress,
  clearSuggestions,
  setAssessmentFormValues,
}) => {
  const [isInformationCertified, certifyInformation] = useState(false);

  const onChangeCity = (e) => {
    const titleCaseValue = titleCase(e.target.value);
    handleChange(e, { newValue: titleCaseValue });
  };

  const goBack = () => {
    onSubmit(CURRENT_STEP - 1);
    selectAddress({});
    setAssessmentFormValues({});
    clearSuggestions();
  };

  const handleCertifyInformationChange = () => {
    certifyInformation(!isInformationCertified);
  };

  return (
    <>
      <GoBackButton title="Change Address" onClick={goBack} />
      <h4 className="title">
        We pulled public records data for your property. Please verify that this
        information is correct.
      </h4>
      <VerifyAddressForm
        goBack={goBack}
        values={values}
        selectedAddress={selectedAddress}
        errors={errors}
        handleChange={handleChange}
        handleCertifyInformationChange={handleCertifyInformationChange}
        isInformationCertified={isInformationCertified}
        onChangeCity={onChangeCity}
        handleSubmit={handleSubmit}
        submitButtonDisabled={!isInformationCertified}
      />
    </>
  );
};

VerifyAddress.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  selectedAddress: PropTypes.object,
};

const validationFunc = (values) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (key === "yearBuilt") {
      if (+values[key] > new Date().getFullYear() || +values[key] < 1900) {
        errors[
          key
        ] = `Property year must be between 1900 and ${new Date().getFullYear()}`;
      }
    }

    if (key === "bedCount") {
      if (!Number.isInteger(+values[key])) {
        errors[key] = `This field needs to be an integer`;
      }
    }

    if (key === "bedCount" || key === "buildingSqFt" || key === "bathCount") {
      if (+values[key] < 1) {
        errors[key] = "This value should be at least 1";
      }
    }

    if (key === "lotSqFt") {
      if (+values[key] < 0) {
        errors[key] = "This value should be at least 0";
      }
    }

    if (values[key] === "") {
      errors[key] = "We need to know this value";
    }
  });

  return errors;
};

const CreateVerifyFormik = withFormik({
  mapPropsToValues: ({
    selectedAddress: {
      bed_count,
      bath_count,
      building_sq_ft,
      lot_sq_ft,
      year_built,
      city,
    },
    assessmentValues,
  }) => ({
    bedCount: assessmentValues.bed_count || bed_count || "",
    bathCount: assessmentValues.bath_count || bath_count || "",
    buildingSqFt: assessmentValues.building_sq_ft || building_sq_ft || "",
    lotSqFt:
      assessmentValues.lot_sq_ft || assessmentValues.lot_sq_ft === 0
        ? assessmentValues.lot_sq_ft
        : lot_sq_ft || lot_sq_ft === 0
        ? lot_sq_ft
        : "",
    yearBuilt: assessmentValues.year_built || year_built || "",
    city: assessmentValues.city || (city ? titleCase(city) : ""),
  }),

  validate: (values) => {
    return validationFunc(values);
  },

  mapPropsToErrors: ({
    selectedAddress: {
      bed_count,
      bath_count,
      building_sq_ft,
      lot_sq_ft,
      year_built,
      city,
    },
    assessmentValues,
  }) => {
    const values = {
      bedCount: assessmentValues.bed_count || bed_count || "",
      bathCount: assessmentValues.bath_count || bath_count || "",
      buildingSqFt: assessmentValues.building_sq_ft || building_sq_ft || "",
      lotSqFt:
        assessmentValues.lot_sq_ft || assessmentValues.lot_sq_ft === 0
          ? assessmentValues.lot_sq_ft
          : lot_sq_ft || lot_sq_ft === 0
          ? lot_sq_ft
          : "",
      yearBuilt: assessmentValues.year_built || year_built || "",
      city: assessmentValues.city || city || "",
    };

    return validationFunc(values);
  },

  handleSubmit: (
    { bedCount, bathCount, buildingSqFt, lotSqFt, yearBuilt, city },
    { props }
  ) => {
    const modifiedAddress = {
      bed_count: bedCount,
      bath_count: bathCount,
      building_sq_ft: buildingSqFt,
      lot_sq_ft: lotSqFt,
      year_built: yearBuilt,
      city,
    };

    props.setAssessmentFormValues(modifiedAddress);

    if (isPreOrderFlow) {
      props.onSubmit(CURRENT_STEP + 1);
      return;
    }
    props.getPropertyAssessment();
    props.history.push("/submit");
  },
});

export default compose(withRouter, CreateVerifyFormik)(VerifyAddress);
