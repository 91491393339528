import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

import { FormButton } from "../../components/Buttons";
import PriceRangeDisplay from "../../components/PriceRangeDisplay";
import { TAX_YEAR } from "../../constants";

const EstimateValue = ({ selectedAddress, uuid }) => {
  const history = useHistory();

  const { appraisedData = {}, county } = selectedAddress;

  const handleOnClick = () => {
    history.push(`/select-report/${uuid}`);
  };

  return (
    <>
      <div className="title">
        <p>
          Based on appraisal data from {TAX_YEAR - 1} in your area, The {county}{" "}
          CAD may be overvaluing your property by:
        </p>
      </div>
      <PriceRangeDisplay appraisedValue={appraisedData.appraised_value} />
      <div className="title">
        <p>
          Protesting your property taxes with your PPT Contestation Report could
          bring down your appraised value and lower your annual tax bill.
        </p>
      </div>
      <FormButton
        className="btn-round mb-4"
        color="neutral"
        onClick={handleOnClick}
      >
        Order My Report Now <i className="fa fa-chevron-right" />
      </FormButton>
      <div className="title">
        <p>
          Ordering now will make sure our staff finishes your report as soon as
          possible when the {county} CAD releases their {TAX_YEAR} appraisals in
          April.
        </p>
      </div>
    </>
  );
};

EstimateValue.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EstimateValue;
