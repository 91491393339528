import React from "react";
import { Container, Row, Col } from "reactstrap";
import { JumboContainer } from "../../components/Loaders/PageLoader";
import Footer from "../../components/Footers/Footer";

const PaymentSuccess = () => (
  <>
    <Container style={{ padding: 0 }}>
      <JumboContainer>
        <Row>
          <Col>
            <h3 className="title">Thank you for your payment!</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              You'll receive an email shortly with your property tax
              contestation report and instructions on how to use it. If you
              don't get an email from us soon please reach out to{" "}
              <a href="mailto:help@protestingpropertytaxes.com">
                help@protestingpropertytaxes.com
              </a>{" "}
              and we'll make things right.
            </h4>
          </Col>
        </Row>
      </JumboContainer>
      <Footer />
    </Container>
  </>
);

export default PaymentSuccess;
