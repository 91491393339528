// vendor
import React from "react";
import { Row, Container } from "reactstrap";
import { Link } from "react-router-dom";

// app
import { CreditsFooter, ContactSection } from "./styled";



function MainFooter() {
  return (
    <footer className="footer footer-black">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <ContactSection>
                  <i className="fa fa-envelope" />
                  <a href="mailto:info@protestingpropertytaxes.com">
                    info@protestingpropertytaxes.com
                  </a>
                </ContactSection>
              </li>
            </ul>
          </nav>
          <CreditsFooter className="credits">
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> in Austin.
            </span>
          </CreditsFooter>
        </Row>
      </Container>
    </footer>
  );
}

export default MainFooter;
