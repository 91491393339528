import isEmpty from "lodash/isEmpty";
import axios from "axios";

import { TOGGLE_UNBOUNCE_MODAL } from "../actionTypes";
import {
  SUBMIT_UNBOUNCE_SUCCESS,
  SUBMIT_UNBOUNCE,
  SUBMIT_UNBOUNCE_FAIL,
} from "../actionTypes";
import { API_URL } from "../../constants";

export const toggleUnbounceModal = (payload) => {
  return {
    type: TOGGLE_UNBOUNCE_MODAL,
    payload,
  };
};

export const submitUnbounce = (email) => (dispatch, getState) => {
  const { freeAssesment } = getState();
  const { selectedAddress } = freeAssesment;

  const payload = {
    email_address: email,
    source: "UnbounceForm",
    notes: "10% Discount",
    prop: null,
  };

  if (!isEmpty(selectedAddress)) {
    console.log("selected address", selectedAddress);
    payload.prop = `${selectedAddress.county} - ${selectedAddress.assessor_id}`;
  }

  console.log(payload);
  dispatch(submitUnbounceStart());
  axios
    .post(`${API_URL}/leads/`, payload)
    .then((res) => {
      dispatch(submitUnbounceSuccess(res));
    })
    .catch(() => submitUnbounceFail());
};

export const submitUnbounceStart = () => ({
  type: SUBMIT_UNBOUNCE,
});

export const submitUnbounceSuccess = () => ({
  type: SUBMIT_UNBOUNCE_SUCCESS,
});

export const submitUnbounceFail = (payload) => ({
  type: SUBMIT_UNBOUNCE_FAIL,
  payload,
});
