// vendor
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router";

// app
import PageLoader from "../../components/Loaders/PageLoader";
import MainFooter from "../../components/Footers/Footer";
import { API_URL } from "../../constants";
import OrderNotFound from "../../components/NotFound/OrderNotFound";

const StripeRedirectPage = () => {
  const { uuid } = useParams();
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!uuid) {
      history.push("/");
    } else {
      axios
        .get(`${API_URL}/orders/${uuid}/download/`)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;
            const reportUrl = data.report_url;
            window.open(reportUrl);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [uuid, history, setError]);

  return (
    <>
      {error ? (
        <OrderNotFound title="We’re sorry, we couldn’t find that report to download. If you’re having problems please email" />
      ) : (
        <PageLoader text="Retrieving your report now ..." />
      )}
      <MainFooter />
    </>
  );
};

export default StripeRedirectPage;
