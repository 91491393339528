import React from "react";

export const termsAndConditions = (
  <>
    <p>
      The software used to create reports is automated (or computer aided) and has not been prepared by an appraiser,
      has not been reviewed by an appraiser, may not be considered to be compliant with Uniform Standards of
      Professional Appraisal Practice (USPAP) and should not be construed as a formal appraisal.
    </p>
    <p>
      The use of this program is for personal use only (i.e. property owners who want information to assist them in
      lowering their own property tax valuation). Any commercial use (regardless of any MLS affiliation) is prohibited.
      Other than by separate agreement, no other firm or individual may resell or use commercially any product by
      ProtestingPropertyTaxes.com.
    </p>
    <p>The reports produced by ProtestingPropertyTaxes.com do not guarantee a reduction in a property tax valuation.</p>
    <ul>
      <li>
        <p>
          ProtestingPropertyTaxes.com, Austin Real Estate Partners, and/or PXN Residential LLC cannot guarantee the
          accuracy of the data used in the reports.
        </p>
      </li>
      <li>
        <p>
          The adjustments made to the comparable properties may not reflect the same calculations the appraisal district
          would use.
        </p>
      </li>
      <li>
        <p>
          The user releases ProtestingPropertyTaxes.com, Austin Real Estate Partners, and/or PXN Residential LLC from
          any liability for any negative impact this report may have on a property tax valuation.
        </p>
      </li>
      <li>
        <p>
          The valuation produced by this report may not be used for any purpose other than as aid to reduce the
          valuation for property tax purposes.
        </p>
      </li>
      <li>
        <p>
          Any customer who purchases a ProtestingPropertyTaxes.com Contestation Report and does not receive a reduction
          in their Appraised Value from the appraisal district or the Appraisal Review Board is eligible for a refund.
          To be eligible, customers must complete the entire protest process, including the formal hearing with the
          Appraisal Review Board. Customers will be provided with step-by-step instructions on how to submit a Notarized
          Affidavit so that they may appear in absentia at the formal hearing. Customers must provide a copy of the
          Appraisal Review Board's hearing decision as proof that they did not receive a reduction in order to receive
          their refund. Customers who withdraw, settle, or do not file their protest are not eligible for refunds. Since
          tax rates and exemptions may influence final tax amounts, ProtestingPropertyTaxes.com does not guarantee that
          a reduction in Appraised Value results in lower taxes.
        </p>
      </li>
    </ul>
    <hr />
    <h4 id="annual-reports">
      ProtestingPropertyTaxes.com Annual Report Customers
    </h4>
    <br />
    <p>
      Yearly report purchasers will be billed each year as soon as a licensed property tax consultant prepares and approves their Contestation Report for the year. If you no longer want to protest yearly, simply send an email to <a href="mailto:help@protestingpropertytaxes.com">help@protestingpropertytaxes.com</a> letting us know you want to cancel and we will no longer automatically create a Contestation Report for you.
    </p>
    <p>
     Refunds are not available once a customer's annual Contestation Report is completed, although yearly report customers are still eligible for the money back guarantee.
     </p>
    <hr />
    <h4 id="referral-program">
      <i>ProtestingPropertyTaxes.com Broker Referral Program</i>
    </h4>
    <br />
    <p>
      <i>
        Each broker has been given a unique referral link based on their publicly available state license number.
        Customers who come to the site using a broker’s referral link will receive a 10% discount for the purchase of a
        tax contestation report in 2022.
      </i>
    </p>
    <p>
      <i>
        Brokers may, by prior arrangement, be compensated by ProtestingPropertyTaxes.com for the purchase of tax
        contestation reports by their clients. Such compensation may take the form of directed donations to nonprofits
        (e.g., Habitat for Humanity of San Antonio), or by direct payments from ProtestingPropertyTaxes.com to brokers
        or their firms.
      </i>
    </p>
    <br />
    <ol type="a">
      <li>
        <p>
          <i>
            Customers that are referred to the site from another promotion run by ProtestingPropertTaxes.com (e.g., a
            discount for the employees of Google’s Austin office) will not be credited towards compensation, even if
            they are clients of the broker.
          </i>
        </p>
      </li>
      <li>
        <p>
          <i>
            Any customer who receives a payment refund (e.g., in accordance with ProtestingPropertyTaxes.com’s
            money-back guarantee) will not be credited towards payment.
          </i>
        </p>
      </li>
    </ol>
    <p>
      <i>ProtestingPropertyTaxes.com reserves the right to alter or suspend the broker referral program at any time.</i>
    </p>
  </>
);
