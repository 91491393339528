// vendor
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router";
import { loadStripe } from "@stripe/stripe-js";

// app
import PageLoader from "../../components/Loaders/PageLoader";
import MainFooter from "../../components/Footers/Footer";
import { API_URL, STRIPE_PUBLIC_KEY } from "../../constants";
import OrderNotFound from "../../components/NotFound/OrderNotFound";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const StripeRedirectPage = ({ subscribe }) => {
  const { orderUuid } = useParams();
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!orderUuid) {
      history.push("/");
    } else {
      const payload = subscribe ? { subscribe: true } : undefined;

      axios
        .post(`${API_URL}/orders/${orderUuid}/checkout/`, payload)
        .then(async (res) => {
          if (res.status === 200) {
            const { data } = res;
            const stripeSessionId = data.stripe_session_id;

            const stripe = await stripePromise;
            await stripe.redirectToCheckout({
              sessionId: stripeSessionId,
            });
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [orderUuid, history, setError, subscribe]);

  return (
    <>
      {error ? (
        <OrderNotFound />
      ) : (
        <PageLoader text="We're Redirecting you to Checkout ... " />
      )}
      <MainFooter />
    </>
  );
};

export default StripeRedirectPage;
